import React from 'react';
import PropTypes from 'prop-types';
import { POLES_LACK } from '../Input/Poles';
import { HOLDER_NO } from '../Input/Holder';

export const THREED5GatewayResponseSecondPage = ({ form }) => {
  const storageWrapper = Object.keys(form.response.productionMaterials.storage).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{form.response.productionMaterials.storage[key].name}</td>
      <td>{form.response.productionMaterials.storage[key].material}</td>
      <td>
        {form.response.productionMaterials.storage[key].amount.value}
        {' '}
        {form.response.productionMaterials.storage[key].amount.unit}
      </td>
      <td />
      <td className="tdCenter">
        <div className="pdfSquare" />
      </td>
    </tr>
  ));

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={7}>BUFOR</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <>
              <th>{' '}</th>
              <th className="responseThSaws">BUFOR</th>
            </>
          </tr>
        </thead>
        <tbody>
          {Object.keys(form.response.productionMaterials.buffer).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{form.response.productionMaterials.buffer[key].name}</td>
              <td>{form.response.productionMaterials.buffer[key].material}</td>
              <td>
                {form.response.productionMaterials.buffer[key].amount.value}
                {' '}
                {form.response.productionMaterials.buffer[key].amount.unit}
              </td>
              <>
                <td />
                <td className="tdCenter">
                  <div className="pdfSquare" />
                </td>
              </>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">MAGAZYN - OSPRZĘT</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th>{' '}</th>
            <th className="responseThSaws">OSPRZĘT</th>
          </tr>
        </thead>
        <tbody>
          {storageWrapper}
        </tbody>
      </table>
    </>
  );
};

const GatewayResponse = ({
  values, hdg, response, isPdf,
}) => {
  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  let studding = null;
  if (Object.keys(response.productionMaterials.studding).length > 0) {
    studding = Object.keys(response.productionMaterials.studding).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.studding[key].name}</td>
        <td>{response.productionMaterials.studding[key].material}</td>
        <td>
          {response.productionMaterials.studding[key].amount.value}
          {' '}
          {response.productionMaterials.studding[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.studding[key].size.value}
          {' '}
          {response.productionMaterials.studding[key].size.unit}
        </td>
        {isPdf && (
          <>
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
            <td />
          </>
        )}
      </tr>
    ));
  }

  const TechnologicalOpening = () => {
    if (hdg && values.poles !== POLES_LACK) {
      if (values.holder === HOLDER_NO) {
        if (response.productionMaterials.saw.poles.size.value <= 3000) {
          return (
            <>
              <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH DO WBETONOWANIA</b></p>
              <p>
                <b>Otwór 1</b>
                {': '}
                od góry 10 mm na mniejszej ściance
              </p>
            </>
          );
        }
        return (
          <>
            <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH DO WBETONOWANIA</b></p>
            <p>
              <b>Otwór 1</b>
              {': '}
              od góry 10 mm na mniejszej ściance
              <>
                {' '}
                |
                {' '}
                <b>Otwór 2</b>
                {': '}
                od dołu 10 mm na mniejszej ściance
              </>
            </p>
          </>
        );
      }
      if (response.productionMaterials.saw.poles.size.value <= 3000) {
        return null;
      }
      return (
        <>
          <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH NA MARCE</b></p>
          <p>
            <b>Otwór 1</b>
            {': '}
            od góry 10 mm na mniejszej ściance
          </p>
        </>
      );
    } return null;
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      {!isPdf && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>BUFOR</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">BUFOR</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.buffer).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.buffer[key].name}</td>
                <td>{response.productionMaterials.buffer[key].material}</td>
                <td>
                  {response.productionMaterials.buffer[key].amount.value}
                  {' '}
                  {response.productionMaterials.buffer[key].amount.unit}
                </td>
                {isPdf && (
                  <>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {response.productionMaterials?.storageRemoval && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - ZDEJMOWANIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">ZDEJMOWANIE</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.storageRemoval).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.storageRemoval[key].name}</td>
                <td>{response.productionMaterials.storageRemoval[key].material}</td>
                <td>
                  {response.productionMaterials.storageRemoval[key].amount.value}
                  {' '}
                  {response.productionMaterials.storageRemoval[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isPdf && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OSPRZĘT</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">OSPRZĘT</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.storage).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.storage[key].name}</td>
                <td>{response.productionMaterials.storage[key].material}</td>
                <td>
                  {response.productionMaterials.storage[key].amount.value}
                  {' '}
                  {response.productionMaterials.storage[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <TechnologicalOpening />
      {Object.keys(response.productionMaterials.studding).length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>NABIJANIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">NABIJANIE</th>
                  <th>{' '}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {studding}
          </tbody>
        </table>
      )}
    </div>
  );
};

GatewayResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GatewayResponse;
