export const FENCE_TYPE_GATE_SELF_SUPPORTING = 'gateSelfSupporting';
export const FENCE_TYPE_GATE_TILTING = 'gateTilting';
export const FENCE_TYPE_FOLDING_GATE = 'foldingGate';
export const FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE = 'singleLeafFoldingGate';
export const FENCE_TYPE_GATEWAY = 'gateway';
export const FENCE_TYPE_SINGLE_LEAF_GATE = 'singleLeafGate';
export const FENCE_TYPE_SPAN = 'span';
export const FENCE_TYPE_NON_SYMMETRICAL_GATE = 'nonSymmetricalGate';
export const FENCE_TYPE_GATE_GATEWAY = 'gateGateway';
export const FENCE_TYPE_GATEWAY_WITH_MACHINE = 'gatewayWithMachine';
export const FENCE_TYPE_BASE_POLYCARBONATE = 'basePolycarbonate';
export const FENCE_TYPE_BASE_PLATE = 'basePlate';
export const FENCE_TYPE_BASE_SOLAR_PANELS = 'baseSolarPanels';
export const FENCE_TYPE_EXTENSION_POLYCARBONATE = 'extensionPolycarbonate';
export const FENCE_TYPE_EXTENSION_PLATE = 'extensionPlate';
export const FENCE_TYPE_EXTENSION_SOLAR_PANELS = 'extensionSolarPanels';
export const FENCE_TYPE_BALLAST = 'ballast';
export const FENCE_TYPE_WINDCHEST = 'windchest';
export const FENCE_TYPE_BALLAST_WITH_WINDCHEST = 'ballastWithWindchest';
export const FENCE_TYPE_GATEWAY_ACCESSORIES = 'gatewayAccessories';
export const FENCE_TYPE_GATE_TILTING_ACCESSORIES = 'gateTiltingAccessories';

export const fencesTypesInPolish = {
  [FENCE_TYPE_GATE_SELF_SUPPORTING]: 'Brama samonośna',
  [FENCE_TYPE_GATE_TILTING]: 'Brama uchylna',
  [FENCE_TYPE_GATEWAY]: 'Furtka',
  [FENCE_TYPE_GATEWAY_WITH_MACHINE]: 'Furtka z automatem',
  [FENCE_TYPE_SINGLE_LEAF_GATE]: 'Brama jednoskrzydłowa',
  [FENCE_TYPE_SPAN]: 'Przęsło',
  [FENCE_TYPE_FOLDING_GATE]: 'Brama łamana',
  [FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE]: 'Brama łamana jednoskrzydłowa',
  [FENCE_TYPE_NON_SYMMETRICAL_GATE]: 'Brama niesymetryczna',
  [FENCE_TYPE_GATE_GATEWAY]: 'Bramo-furtka',
  [FENCE_TYPE_BASE_POLYCARBONATE]: 'Baza poliwęglan',
  [FENCE_TYPE_BASE_PLATE]: 'Baza płyta trapezowa',
  [FENCE_TYPE_BASE_SOLAR_PANELS]: 'Baza fotowoltaika',
  [FENCE_TYPE_EXTENSION_POLYCARBONATE]: 'Rozbudowa poliwęglan',
  [FENCE_TYPE_EXTENSION_PLATE]: 'Rozbudowa płyta trapezowa',
  [FENCE_TYPE_EXTENSION_SOLAR_PANELS]: 'Rozbudowa fotowoltaika',
  [FENCE_TYPE_BALLAST]: 'Balast',
  [FENCE_TYPE_WINDCHEST]: 'Wiatrownica',
  [FENCE_TYPE_BALLAST_WITH_WINDCHEST]: 'Balast z wiatrownicą',
  [FENCE_TYPE_GATEWAY_ACCESSORIES]: 'Furtka Akcesoria',
  [FENCE_TYPE_GATE_TILTING_ACCESSORIES]: 'Brama dwuskrzydłowa Akcesoria',
};

export const fencesTypes = [
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_GATEWAY_WITH_MACHINE,
  FENCE_TYPE_SINGLE_LEAF_GATE,
  FENCE_TYPE_SPAN,
  FENCE_TYPE_FOLDING_GATE,
  FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE,
  FENCE_TYPE_NON_SYMMETRICAL_GATE,
  FENCE_TYPE_GATE_GATEWAY,
];

export default fencesTypes;
