import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import * as Sentry from '@sentry/browser';

import { useDispatch, useSelector } from 'react-redux';
import {
  setTokens, doesUserHasRole, loadUserFromToken, loadAndSetUserWorkingPlace,
} from 'auth';

import {
  Container,
  LoginContainerRow,
  ContainerCol4,
  LoginFormContainer,
  LoginFormInput,
  InputContainer,
  ButtonsContainer,
  LoginButton,
  ButtonText,
  Image,
  ResponseContainer,
} from 'Pages/shared/components';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(3).required(),
});

const IconStyle = {
  position: 'absolute',
  opacity: '0.5',
  top: '10px',
  left: '15px',
};

const SignIn = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState('');
  const [error, setError] = useState(false);
  const authenticated = useSelector((s) => s.app.authenticated);
  const authError = useSelector((s) => s.app.authError);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      setInfo('');
      setError(false);
      axios
        .post('/login', {
          username: values.email,
          password: values.password,
        })
        .then(({ data }) => {
          const user = loadUserFromToken(data?.token);
          if (user) {
            if (!doesUserHasRole('ROLE_MATERIAL_CARD', user.roles)) {
              setInfo('Ten użytkownik nie ma odpowiednich uprawnień.');
              setError(true);
              return;
            }
            // robsztal dodac workingPlace
            loadAndSetUserWorkingPlace();
            setTokens(data.token, data.refresh_token);
            dispatch({ type: 'SIGN_IN' });
          } else {
            setInfo('Coś poszło nie tak.');
            setError(true);
          }
        })
        .catch((err) => {
          if (err?.response?.status >= 400) {
            setInfo('Zły login lub hasło.');
            setError(true);
            return;
          }
          Sentry.captureException(err);
        });
    },
    validationSchema,
  });
  if (authenticated) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <FormikProvider value={formik}>
      <Container>
        <div className="container-xl">
          <LoginContainerRow className="row">
            <ContainerCol4 className="col-sm-4">
              <LoginFormContainer>
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <h1>Logowanie</h1>
                  </div>
                  <div>
                    <p>
                      Wyliczaj materiały,
                      <br />
                      szybko i wygodnie.
                    </p>
                  </div>
                  <InputContainer className="form-group">
                    <FontAwesomeIcon icon={faUser} style={IconStyle} />
                    <LoginFormInput
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Login"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                  </InputContainer>
                  <InputContainer className="form-group">
                    <FontAwesomeIcon icon={faLock} style={IconStyle} />
                    <LoginFormInput
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Hasło"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </InputContainer>
                  <Link to="/reset-password">Zapomniałem hasła</Link>
                  <ButtonsContainer>
                    <LoginButton className="btn btn-danger">
                      <ButtonText>Zaloguj</ButtonText>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </LoginButton>
                  </ButtonsContainer>
                  <ResponseContainer error={error || authError}>
                    {info}
                    {authError && (
                    <span>
                      Wystąpił błąd po zalogowaniu
                      <br />
                      (
                      {authError}
                      )
                    </span>
                    )}
                  </ResponseContainer>
                </form>
              </LoginFormContainer>
            </ContainerCol4>
            <div className="col-sm-8">
              <div>
                <Image src="img/home-background.jpg" alt="" />
              </div>
            </div>
          </LoginContainerRow>
        </div>
      </Container>
    </FormikProvider>
  );
};

export default SignIn;
