import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import FormActions from '../Input/FormActions';
import invoke from '../../lambda';
import SpanResponse from './SpanResponse';

const Span = ({ form, updateForm }) => {
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      width: form.values.width || '',
      moduleHeight: form.values.moduleHeight || '',
      spacing: form.values.spacing || 0,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'P302 + PB002',
        type: 'span',
        ...values,
      });

      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({
      height, width, moduleHeight, spacing,
    }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      } else if (height < 300) {
        errors.height = 'Minimalna wysokość to 300mm.';
      } else if (width < 1440 && height > 2000) {
        errors.height = 'Maksymalna wysokość to 2000mm.';
      } else if (width > 1440 && height > 1440) {
        errors.height = 'Maksymalna wysokość to 1440mm.';
      }
      if (!width) {
        errors.width = 'Wymagana wartość';
      } else if (width < 200) {
        errors.width = 'Minimalna szerokość to 200mm.';
      } else if (width > 2940) {
        errors.width = 'Maksymalna szerokość to 2940mm.';
      }
      if (!moduleHeight) {
        errors.moduleHeight = 'Wymagana wartość';
      } else if (moduleHeight < 300) {
        errors.moduleHeight = 'Minimalna wysokość modułu to 300mm.';
      } else if (moduleHeight > 1000) {
        errors.moduleHeight = 'Maksymalna wysokość modułu to 1000mm.';
      }
      if (spacing < 0) {
        errors.spacing = 'Minimalny odstęp to 0mm.';
      } else if (spacing > 100) {
        errors.spacing = 'Maksymalny odstęp to 100mm.';
      }

      return errors;
    },
  });
  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            onChange={formik.handleChange}
            autoComplete="off"
            required
          />
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`width__${form.id}`}>Szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`width__${form.id}`}
            name="width"
            value={formik.values.width}
            onChange={formik.handleChange}
            autoComplete="off"
            required
          />
          <span className="error">{formik.errors.width && formik.errors.width}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`moduleHeight__${form.id}`}>Wysokość modułu (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`moduleHeight__${form.id}`}
            name="moduleHeight"
            value={formik.values.moduleHeight}
            onChange={formik.handleChange}
            autoComplete="off"
            required
          />
          <span className="error">{formik.errors.moduleHeight && formik.errors.moduleHeight}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`spacing__${form.id}`}>Odstępy (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`spacing__${form.id}`}
            name="spacing"
            value={formik.values.spacing}
            onChange={formik.handleChange}
            autoComplete="off"
            required
          />
          <span className="error">{formik.errors.spacing && formik.errors.spacing}</span>
        </div>
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <SpanResponse response={form.response} isPdf={false} />}
    </form>
  );
};

Span.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Span;
