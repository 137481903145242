import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable camelcase */
import PP002P82_GatewayResponse from '../PP002P82/GatewayResponse';
import PP002P82_GateGatewayResponse from '../PP002P82/GateGatewayResponse';
import PP002P82_NonSymmetricalGateResponse from '../PP002P82/NonSymmetricalGateResponse';
import PP002P82_SingleLeafGateResponse from '../PP002P82/SingleLeafGateResponse';
import PP002P82_GateTiltingResponse from '../PP002P82/GateTiltingResponse';
import PP002P82_FoldingGateResponse from '../PP002P82/FoldingGateResponse';
import PP002P82_SingleLeafFoldingGateResponse from '../PP002P82/SingleLeafFoldingGateResponse';
import PP002P82_GateSelfSupportingResponse from '../PP002P82/GateSelfSupportingResponse';
import PP002P82_SpanResponse from '../PP002P82/SpanResponse';
import PP002P82_0_GatewayResponse from '../PP002P82_0/GatewayResponse';
import PP002P82_0_GateGatewayResponse from '../PP002P82_0/GateGatewayResponse';
import PP002P82_0_NonSymmetricalGateResponse from '../PP002P82_0/NonSymmetricalGateResponse';
import PP002P82_0_SingleLeafGateResponse from '../PP002P82_0/SingleLeafGateResponse';
import PP002P82_0_GateTiltingResponse from '../PP002P82_0/GateTiltingResponse';
import PP002P82_0_FoldingGateResponse from '../PP002P82_0/FoldingGateResponse';
import PP002P82_0_SingleLeafFoldingGateResponse from '../PP002P82_0/SingleLeafFoldingGateResponse';
import PP002P82_0_GateSelfSupportingResponse from '../PP002P82_0/GateSelfSupportingResponse';
import PP002P82_0_SpanResponse from '../PP002P82_0/SpanResponse';
import PB001_GatewayResponse from '../PB001/GatewayResponse';
import PB001_GateGatewayResponse from '../PB001/GateGatewayResponse';
import PB001_NonSymmetricalGateResponse from '../PB001/NonSymmetricalGateResponse';
import PB001_SingleLeafGateResponse from '../PB001/SingleLeafGateResponse';
import PB001_GateTiltingResponse from '../PB001/GateTiltingResponse';
import PB001_FoldingGateResponse from '../PB001/FoldingGateResponse';
import PB001_SingleLeafFoldingGateResponse from '../PB001/SingleLeafFoldingGateResponse';
import PB001_GateSelfSupportingResponse from '../PB001/GateSelfSupportingResponse';
import PB001_SpanResponse from '../PB001/SpanResponse';
import PB003_SpanResponse from '../PB003/SpanResponse';
import PB003_GatewayResponse from '../PB003/GatewayResponse';
import PB003_GateGatewayResponse from '../PB003/GateGatewayResponse';
import PB003_NonSymmetricalGateResponse from '../PB003/NonSymmetricalGateResponse';
import PB003_SingleLeafGateResponse from '../PB003/SingleLeafGateResponse';
import PB003_GateTiltingResponse from '../PB003/GateTiltingResponse';
import PB003_FoldingGateResponse from '../PB003/FoldingGateResponse';
import PB003_SingleLeafFoldingGateResponse from '../PB003/SingleLeafFoldingGateResponse';
import PB003_GateSelfSupportingResponse from '../PB003/GateSelfSupportingResponse';
import PP001C_GatewayResponse from '../PP001C/GatewayResponse';
import PP001C_GateGatewayResponse from '../PP001C/GateGatewayResponse';
import PP001C_NonSymmetricalGateResponse from '../PP001C/NonSymmetricalGateResponse';
import PP001C_SingleLeafGateResponse from '../PP001C/SingleLeafGateResponse';
import PP001C_GateTiltingResponse from '../PP001C/GateTiltingResponse';
import PP001C_GateSelfSupportingResponse from '../PP001C/GateSelfSupportingResponse';
import PP001C_SpanResponse from '../PP001C/SpanResponse';
import ThreeD4_GatewayResponse from '../ThreeD4/GatewayResponse';
import ThreeD4_GateGatewayResponse from '../ThreeD4/GateGatewayResponse';
import ThreeD4_NonSymmetricalGateResponse from '../ThreeD4/NonSymmetricalGateResponse';
import ThreeD4_SingleLeafGateResponse from '../ThreeD4/SingleLeafGateResponse';
import ThreeD4_GateTiltingResponse from '../ThreeD4/GateTiltingResponse';
import ThreeD4_GateSelfSupportingResponse from '../ThreeD4/GateSelfSupportingResponse';
import ThreeD5_GatewayResponse from '../ThreeD5/GatewayResponse';
import ThreeD5_GateGatewayResponse from '../ThreeD5/GateGatewayResponse';
import ThreeD5_NonSymmetricalGateResponse from '../ThreeD5/NonSymmetricalGateResponse';
import ThreeD5_SingleLeafGateResponse from '../ThreeD5/SingleLeafGateResponse';
import ThreeD5_GateTiltingResponse from '../ThreeD5/GateTiltingResponse';
import ThreeD5_GateSelfSupportingResponse from '../ThreeD5/GateSelfSupportingResponse';
import TwoD_GatewayResponse from '../TwoD/GatewayResponse';
import TwoD_GateGatewayResponse from '../TwoD/GateGatewayResponse';
import TwoD_NonSymmetricalGateResponse from '../TwoD/NonSymmetricalGateResponse';
import TwoD_SingleLeafGateResponse from '../TwoD/SingleLeafGateResponse';
import TwoD_GateTiltingResponse from '../TwoD/GateTiltingResponse';
import TwoD_FoldingGateResponse from '../TwoD/FoldingGateResponse';
import TwoD_SingleLeafFoldingGateResponse from '../TwoD/SingleLeafFoldingGateResponse';
import TwoD_GateSelfSupportingResponse from '../TwoD/GateSelfSupportingResponse';
import TwoDS_GatewayResponse from '../TwoDS/GatewayResponse';
import TwoDS_GateGatewayResponse from '../TwoDS/GateGatewayResponse';
import TwoDS_NonSymmetricalGateResponse from '../TwoDS/NonSymmetricalGateResponse';
import TwoDS_SingleLeafGateResponse from '../TwoDS/SingleLeafGateResponse';
import TwoDS_GateTiltingResponse from '../TwoDS/GateTiltingResponse';
import TwoDS_FoldingGateResponse from '../TwoDS/FoldingGateResponse';
import TwoDS_SingleLeafFoldingGateResponse from '../TwoDS/SingleLeafFoldingGateResponse';
import TwoDS_GateSelfSupportingResponse from '../TwoDS/GateSelfSupportingResponse';
import PP001CC_GatewayResponse from '../PP001CC/GatewayResponse';
import PP001CC_GateGatewayResponse from '../PP001CC/GateGatewayResponse';
import PP001CC_NonSymmetricalGateResponse from '../PP001CC/NonSymmetricalGateResponse';
import PP001CC_SingleLeafGateResponse from '../PP001CC/SingleLeafGateResponse';
import PP001CC_GateTiltingResponse from '../PP001CC/GateTiltingResponse';
import PP001CC_GateSelfSupportingResponse from '../PP001CC/GateSelfSupportingResponse';
import PP001CC_SpanResponse from '../PP001CC/SpanResponse';
import PP001N_GatewayResponse from '../PP001N/GatewayResponse';
import PP001N_GateGatewayResponse from '../PP001N/GateGatewayResponse';
import PP001N_NonSymmetricalGateResponse from '../PP001N/NonSymmetricalGateResponse';
import PP001N_SingleLeafGateResponse from '../PP001N/SingleLeafGateResponse';
import PP001N_GateTiltingResponse from '../PP001N/GateTiltingResponse';
import PP001N_GateSelfSupportingResponse from '../PP001N/GateSelfSupportingResponse';
import PP001N_SpanResponse from '../PP001N/SpanResponse';
import PP001W_GatewayResponse from '../PP001W/GatewayResponse';
import PP001W_GateGatewayResponse from '../PP001W/GateGatewayResponse';
import PP001W_NonSymmetricalGateResponse from '../PP001W/NonSymmetricalGateResponse';
import PP001W_SingleLeafGateResponse from '../PP001W/SingleLeafGateResponse';
import PP001W_GateTiltingResponse from '../PP001W/GateTiltingResponse';
import PP001W_GateSelfSupportingResponse from '../PP001W/GateSelfSupportingResponse';
import PP001W_SpanResponse from '../PP001W/SpanResponse';
import PP002P82COLORE1_GatewayResponse from '../PP002P82Colore/GatewayResponse';
import PP002P82COLORE1_GateGatewayResponse from '../PP002P82Colore/GateGatewayResponse';
import PP002P82COLORE1_NonSymmetricalGateResponse from '../PP002P82Colore/NonSymmetricalGateResponse';
import PP002P82COLORE1_SingleLeafGateResponse from '../PP002P82Colore/SingleLeafGateResponse';
import PP002P82COLORE1_GateTiltingResponse from '../PP002P82Colore/GateTiltingResponse';
import PP002P82COLORE1_FoldingGateResponse from '../PP002P82Colore/FoldingGateResponse';
import PP002P82COLORE1_SingleLeafFoldingGateResponse from '../PP002P82Colore/SingleLeafFoldingGateResponse';
import PP002P82COLORE1_GateSelfSupportingResponse from '../PP002P82Colore/GateSelfSupportingResponse';
import PP002P82COLORE1_SpanResponse from '../PP002P82Colore/SpanResponse';
import PP002P82COLORE2_GatewayResponse from '../PP002P82Colore2/GatewayResponse';
import PP002P82COLORE2_GateGatewayResponse from '../PP002P82Colore2/GateGatewayResponse';
import PP002P82COLORE2_NonSymmetricalGateResponse from '../PP002P82Colore2/NonSymmetricalGateResponse';
import PP002P82COLORE2_SingleLeafGateResponse from '../PP002P82Colore2/SingleLeafGateResponse';
import PP002P82COLORE2_GateTiltingResponse from '../PP002P82Colore2/GateTiltingResponse';
import PP002P82COLORE2_FoldingGateResponse from '../PP002P82Colore2/FoldingGateResponse';
import PP002P82COLORE2_SingleLeafFoldingGateResponse from '../PP002P82Colore2/SingleLeafFoldingGateResponse';
import PP002P82COLORE2_GateSelfSupportingResponse from '../PP002P82Colore2/GateSelfSupportingResponse';
import PP002P82COLORE2_SpanResponse from '../PP002P82Colore2/SpanResponse';
import PP002P82COLORE1_0_GatewayResponse from '../PP002P82Colore_0/GatewayResponse';
import PP002P82COLORE1_0_GateGatewayResponse from '../PP002P82Colore_0/GateGatewayResponse';
import PP002P82COLORE1_0_NonSymmetricalGateResponse from '../PP002P82Colore_0/NonSymmetricalGateResponse';
import PP002P82COLORE1_0_SingleLeafGateResponse from '../PP002P82Colore_0/SingleLeafGateResponse';
import PP002P82COLORE1_0_GateTiltingResponse from '../PP002P82Colore_0/GateTiltingResponse';
import PP002P82COLORE1_0_FoldingGateResponse from '../PP002P82Colore_0/FoldingGateResponse';
import PP002P82COLORE1_0_SingleLeafFoldingGateResponse from '../PP002P82Colore_0/SingleLeafFoldingGateResponse';
import PP002P82COLORE1_0_GateSelfSupportingResponse from '../PP002P82Colore_0/GateSelfSupportingResponse';
import PP002P82COLORE1_0_SpanResponse from '../PP002P82Colore_0/SpanResponse';
import PP002P82COLORE2_0_GatewayResponse from '../PP002P82Colore2_0/GatewayResponse';
import PP002P82COLORE2_0_GateGatewayResponse from '../PP002P82Colore2_0/GateGatewayResponse';
import PP002P82COLORE2_0_NonSymmetricalGateResponse from '../PP002P82Colore2_0/NonSymmetricalGateResponse';
import PP002P82COLORE2_0_SingleLeafGateResponse from '../PP002P82Colore2_0/SingleLeafGateResponse';
import PP002P82COLORE2_0_GateTiltingResponse from '../PP002P82Colore2_0/GateTiltingResponse';
import PP002P82COLORE2_0_FoldingGateResponse from '../PP002P82Colore2_0/FoldingGateResponse';
import PP002P82COLORE2_0_SingleLeafFoldingGateResponse from '../PP002P82Colore2_0/SingleLeafFoldingGateResponse';
import PP002P82COLORE2_0_GateSelfSupportingResponse from '../PP002P82Colore2_0/GateSelfSupportingResponse';
import PP002P82COLORE2_0_SpanResponse from '../PP002P82Colore2_0/SpanResponse';
import PP002P102_GatewayResponse from '../PP002P102/GatewayResponse';
import PP002P102_GateGatewayResponse from '../PP002P102/GateGatewayResponse';
import PP002P102_NonSymmetricalGateResponse from '../PP002P102/NonSymmetricalGateResponse';
import PP002P102_SingleLeafGateResponse from '../PP002P102/SingleLeafGateResponse';
import PP002P102_GateTiltingResponse from '../PP002P102/GateTiltingResponse';
import PP002P102_FoldingGateResponse from '../PP002P102/FoldingGateResponse';
import PP002P102_SingleLeafFoldingGateResponse from '../PP002P102/SingleLeafFoldingGateResponse';
import PP002P102_GateSelfSupportingResponse from '../PP002P102/GateSelfSupportingResponse';
import PP002P102_SpanResponse from '../PP002P102/SpanResponse';
import PP002P102_0_GatewayResponse from '../PP002P102_0/GatewayResponse';
import PP002P102_0_GateGatewayResponse from '../PP002P102_0/GateGatewayResponse';
import PP002P102_0_NonSymmetricalGateResponse from '../PP002P102_0/NonSymmetricalGateResponse';
import PP002P102_0_SingleLeafGateResponse from '../PP002P102_0/SingleLeafGateResponse';
import PP002P102_0_GateTiltingResponse from '../PP002P102_0/GateTiltingResponse';
import PP002P102_0_FoldingGateResponse from '../PP002P102_0/FoldingGateResponse';
import PP002P102_0_SingleLeafFoldingGateResponse from '../PP002P102_0/SingleLeafFoldingGateResponse';
import PP002P102_0_GateSelfSupportingResponse from '../PP002P102_0/GateSelfSupportingResponse';
import PP002P102_0_SpanResponse from '../PP002P102_0/SpanResponse';
import PP001P46_GatewayResponse from '../PP001P46/GatewayResponse';
import PP001P46_GateGatewayResponse from '../PP001P46/GateGatewayResponse';
import PP001P46_NonSymmetricalGateResponse from '../PP001P46/NonSymmetricalGateResponse';
import PP001P46_SingleLeafGateResponse from '../PP001P46/SingleLeafGateResponse';
import PP001P46_GateTiltingResponse from '../PP001P46/GateTiltingResponse';
import PP001P46_GateSelfSupportingResponse from '../PP001P46/GateSelfSupportingResponse';
import PP001P46_SpanResponse from '../PP001P46/SpanResponse';
import PP002P64_GatewayResponse from '../PP002P64/GatewayResponse';
import PP002P64_GateGatewayResponse from '../PP002P64/GateGatewayResponse';
import PP002P64_NonSymmetricalGateResponse from '../PP002P64/NonSymmetricalGateResponse';
import PP002P64_SingleLeafGateResponse from '../PP002P64/SingleLeafGateResponse';
import PP002P64_GateTiltingResponse from '../PP002P64/GateTiltingResponse';
import PP002P64_FoldingGateResponse from '../PP002P64/FoldingGateResponse';
import PP002P64_SingleLeafFoldingGateResponse from '../PP002P64/SingleLeafFoldingGateResponse';
import PP002P64_GateSelfSupportingResponse from '../PP002P64/GateSelfSupportingResponse';
import PP002P64_SpanResponse from '../PP002P64/SpanResponse';
import P302_SpanResponse from '../P302/SpanResponse';
import P302_GatewayResponse from '../P302/GatewayResponse';
import P302_GateGatewayResponse from '../P302/GateGatewayResponse';
import P302_NonSymmetricalGateResponse from '../P302/NonSymmetricalGateResponse';
import P302_SingleLeafGateResponse from '../P302/SingleLeafGateResponse';
import P302_GateTiltingResponse from '../P302/GateTiltingResponse';
import P302_FoldingGateResponse from '../P302/FoldingGateResponse';
import P302_SingleLeafFoldingGateResponse from '../P302/SingleLeafFoldingGateResponse';
import P302_GateSelfSupportingResponse from '../P302/GateSelfSupportingResponse';
import P302PB002_SpanResponse from '../P302PB002/SpanResponse';
import P302PB002_GatewayResponse from '../P302PB002/GatewayResponse';
import P302PB002_GateGatewayResponse from '../P302PB002/GateGatewayResponse';
import P302PB002_NonSymmetricalGateResponse from '../P302PB002/NonSymmetricalGateResponse';
import P302PB002_SingleLeafGateResponse from '../P302PB002/SingleLeafGateResponse';
import P302PB002_GateTiltingResponse from '../P302PB002/GateTiltingResponse';
import P302PB002_FoldingGateResponse from '../P302PB002/FoldingGateResponse';
import P302PB002_SingleLeafFoldingGateResponse from '../P302PB002/SingleLeafFoldingGateResponse';
import P302PB002_GateSelfSupportingResponse from '../P302PB002/GateSelfSupportingResponse';
import P302PS005_SpanResponse from '../P302PS005/SpanResponse';
import P302PS005_GatewayResponse from '../P302PS005/GatewayResponse';
import P302PS005_GateGatewayResponse from '../P302PS005/GateGatewayResponse';
import P302PS005_NonSymmetricalGateResponse from '../P302PS005/NonSymmetricalGateResponse';
import P302PS005_SingleLeafGateResponse from '../P302PS005/SingleLeafGateResponse';
import P302PS005_GateTiltingResponse from '../P302PS005/GateTiltingResponse';
import P302PS005_FoldingGateResponse from '../P302PS005/FoldingGateResponse';
import P302PS005_SingleLeafFoldingGateResponse from '../P302PS005/SingleLeafFoldingGateResponse';
import P302PS005_GateSelfSupportingResponse from '../P302PS005/GateSelfSupportingResponse';
import PP002P_SpanResponse from '../PP002P/SpanResponse';
import PP002P_GatewayResponse from '../PP002P/GatewayResponse';
import PP002P_GateGatewayResponse from '../PP002P/GateGatewayResponse';
import PP002P_NonSymmetricalGateResponse from '../PP002P/NonSymmetricalGateResponse';
import PP002P_SingleLeafGateResponse from '../PP002P/SingleLeafGateResponse';
import PP002P_GateTiltingResponse from '../PP002P/GateTiltingResponse';
import PP002P_FoldingGateResponse from '../PP002P/FoldingGateResponse';
import PP002P_SingleLeafFoldingGateResponse from '../PP002P/SingleLeafFoldingGateResponse';
import PP002P_GateSelfSupportingResponse from '../PP002P/GateSelfSupportingResponse';
import PP002_SpanResponse from '../PP002/SpanResponse';
import PP002_GatewayResponse from '../PP002/GatewayResponse';
import PP002_GateGatewayResponse from '../PP002/GateGatewayResponse';
import PP002_NonSymmetricalGateResponse from '../PP002/NonSymmetricalGateResponse';
import PP002_SingleLeafGateResponse from '../PP002/SingleLeafGateResponse';
import PP002_GateTiltingResponse from '../PP002/GateTiltingResponse';
import PP002_FoldingGateResponse from '../PP002/FoldingGateResponse';
import PP002_SingleLeafFoldingGateResponse from '../PP002/SingleLeafFoldingGateResponse';
import PP002_GateSelfSupportingResponse from '../PP002/GateSelfSupportingResponse';
import PP002Diverso_GatewayResponse from '../PP002Diverso/GatewayResponse';
import PP002Diverso_GateGatewayResponse from '../PP002Diverso/GateGatewayResponse';
import PP002Diverso_NonSymmetricalGateResponse from '../PP002Diverso/NonSymmetricalGateResponse';
import PP002Diverso_SingleLeafGateResponse from '../PP002Diverso/SingleLeafGateResponse';
import PP002Diverso_GateTiltingResponse from '../PP002Diverso/GateTiltingResponse';
import PP002Diverso_FoldingGateResponse from '../PP002Diverso/FoldingGateResponse';
import PP002Diverso_SingleLeafFoldingGateResponse from '../PP002Diverso/SingleLeafFoldingGateResponse';
import PP002Diverso_GateSelfSupportingResponse from '../PP002Diverso/GateSelfSupportingResponse';
import PP002Diverso_SpanResponse from '../PP002Diverso/SpanResponse';
import PP002Diverso1_GatewayResponse from '../PP002Diverso1/GatewayResponse';
import PP002Diverso1_GateGatewayResponse from '../PP002Diverso1/GateGatewayResponse';
import PP002Diverso1_NonSymmetricalGateResponse from '../PP002Diverso1/NonSymmetricalGateResponse';
import PP002Diverso1_SingleLeafGateResponse from '../PP002Diverso1/SingleLeafGateResponse';
import PP002Diverso1_GateTiltingResponse from '../PP002Diverso1/GateTiltingResponse';
import PP002Diverso1_FoldingGateResponse from '../PP002Diverso1/FoldingGateResponse';
import PP002Diverso1_SingleLeafFoldingGateResponse from '../PP002Diverso1/SingleLeafFoldingGateResponse';
import PP002Diverso1_GateSelfSupportingResponse from '../PP002Diverso1/GateSelfSupportingResponse';
import PP002Diverso1_SpanResponse from '../PP002Diverso1/SpanResponse';
import PP002Diverso2_GatewayResponse from '../PP002Diverso2/GatewayResponse';
import PP002Diverso2_GateGatewayResponse from '../PP002Diverso2/GateGatewayResponse';
import PP002Diverso2_NonSymmetricalGateResponse from '../PP002Diverso2/NonSymmetricalGateResponse';
import PP002Diverso2_SingleLeafGateResponse from '../PP002Diverso2/SingleLeafGateResponse';
import PP002Diverso2_GateTiltingResponse from '../PP002Diverso2/GateTiltingResponse';
import PP002Diverso2_FoldingGateResponse from '../PP002Diverso2/FoldingGateResponse';
import PP002Diverso2_SingleLeafFoldingGateResponse from '../PP002Diverso2/SingleLeafFoldingGateResponse';
import PP002Diverso2_GateSelfSupportingResponse from '../PP002Diverso2/GateSelfSupportingResponse';
import PP002Diverso2_SpanResponse from '../PP002Diverso2/SpanResponse';
import PP002Diverso3_GatewayResponse from '../PP002Diverso3/GatewayResponse';
import PP002Diverso3_GateGatewayResponse from '../PP002Diverso3/GateGatewayResponse';
import PP002Diverso3_NonSymmetricalGateResponse from '../PP002Diverso3/NonSymmetricalGateResponse';
import PP002Diverso3_SingleLeafGateResponse from '../PP002Diverso3/SingleLeafGateResponse';
import PP002Diverso3_GateTiltingResponse from '../PP002Diverso3/GateTiltingResponse';
import PP002Diverso3_FoldingGateResponse from '../PP002Diverso3/FoldingGateResponse';
import PP002Diverso3_SingleLeafFoldingGateResponse from '../PP002Diverso3/SingleLeafFoldingGateResponse';
import PP002Diverso3_GateSelfSupportingResponse from '../PP002Diverso3/GateSelfSupportingResponse';
import PP002Diverso3_SpanResponse from '../PP002Diverso3/SpanResponse';
import PP002P82Mix_GatewayResponse from '../PP002P82Mix/GatewayResponse';
import PP002P82Mix_GateGatewayResponse from '../PP002P82Mix/GateGatewayResponse';
import PP002P82Mix_NonSymmetricalGateResponse from '../PP002P82Mix/NonSymmetricalGateResponse';
import PP002P82Mix_SingleLeafGateResponse from '../PP002P82Mix/SingleLeafGateResponse';
import PP002P82Mix_GateTiltingResponse from '../PP002P82Mix/GateTiltingResponse';
import PP002P82Mix_FoldingGateResponse from '../PP002P82Mix/FoldingGateResponse';
import PP002P82Mix_SingleLeafFoldingGateResponse from '../PP002P82Mix/SingleLeafFoldingGateResponse';
import PP002P82Mix_GateSelfSupportingResponse from '../PP002P82Mix/GateSelfSupportingResponse';
import PP002P82Mix_SpanResponse from '../PP002P82Mix/SpanResponse';
import PP002P82Noce1_GatewayResponse from '../PP002P82Noce/GatewayResponse';
import PP002P82Noce1_GateGatewayResponse from '../PP002P82Noce/GateGatewayResponse';
import PP002P82Noce1_NonSymmetricalGateResponse from '../PP002P82Noce/NonSymmetricalGateResponse';
import PP001P82Noce1_SingleLeafGateResponse from '../PP002P82Noce/SingleLeafGateResponse';
import PP002P82Noce1_GateTiltingResponse from '../PP002P82Noce/GateTiltingResponse';
import PP002P82Noce1_FoldingGateResponse from '../PP002P82Noce/FoldingGateResponse';
import PP002P82Noce1_SingleLeafFoldingGateResponse from '../PP002P82Noce/SingleLeafFoldingGateResponse';
import PP002P82Noce1_GateSelfSupportingResponse from '../PP002P82Noce/GateSelfSupportingResponse';
import PP002P82Noce1_SpanResponse from '../PP002P82Noce/SpanResponse';
import PP002P82Noce2_GatewayResponse from '../PP002P82Noce2/GatewayResponse';
import PP002P82Noce2_GateGatewayResponse from '../PP002P82Noce2/GateGatewayResponse';
import PP002P82Noce2_NonSymmetricalGateResponse from '../PP002P82Noce2/NonSymmetricalGateResponse';
import PP002P82Noce2_SingleLeafGateResponse from '../PP002P82Noce2/SingleLeafGateResponse';
import PP002P82Noce2_GateTiltingResponse from '../PP002P82Noce2/GateTiltingResponse';
import PP002P82Noce2_FoldingGateResponse from '../PP002P82Noce2/FoldingGateResponse';
import PP002P82Noce2_SingleLeafFoldingGateResponse from '../PP002P82Noce2/SingleLeafFoldingGateResponse';
import PP002P82Noce2_GateSelfSupportingResponse from '../PP002P82Noce2/GateSelfSupportingResponse';
import PP002P82Noce2_SpanResponse from '../PP002P82Noce2/SpanResponse';
import PP002P82Noce1_0_GatewayResponse from '../PP002P82Noce_0/GatewayResponse';
import PP002P82Noce1_0_GateGatewayResponse from '../PP002P82Noce_0/GateGatewayResponse';
import PP002P82Noce1_0_NonSymmetricalGateResponse from '../PP002P82Noce_0/NonSymmetricalGateResponse';
import PP001P82Noce1_0_SingleLeafGateResponse from '../PP002P82Noce_0/SingleLeafGateResponse';
import PP002P82Noce1_0_GateTiltingResponse from '../PP002P82Noce_0/GateTiltingResponse';
import PP002P82Noce1_0_FoldingGateResponse from '../PP002P82Noce_0/FoldingGateResponse';
import PP002P82Noce1_0_SingleLeafFoldingGateResponse from '../PP002P82Noce_0/SingleLeafFoldingGateResponse';
import PP002P82Noce1_0_GateSelfSupportingResponse from '../PP002P82Noce_0/GateSelfSupportingResponse';
import PP002P82Noce1_0_SpanResponse from '../PP002P82Noce_0/SpanResponse';
import PP002P82Noce2_0_GatewayResponse from '../PP002P82Noce2_0/GatewayResponse';
import PP002P82Noce2_0_GateGatewayResponse from '../PP002P82Noce2_0/GateGatewayResponse';
import PP002P82Noce2_0_NonSymmetricalGateResponse from '../PP002P82Noce2_0/NonSymmetricalGateResponse';
import PP002P82Noce2_0_SingleLeafGateResponse from '../PP002P82Noce2_0/SingleLeafGateResponse';
import PP002P82Noce2_0_GateTiltingResponse from '../PP002P82Noce2_0/GateTiltingResponse';
import PP002P82Noce2_0_FoldingGateResponse from '../PP002P82Noce2_0/FoldingGateResponse';
import PP002P82Noce2_0_SingleLeafFoldingGateResponse from '../PP002P82Noce2_0/SingleLeafFoldingGateResponse';
import PP002P82Noce2_0_GateSelfSupportingResponse from '../PP002P82Noce2_0/GateSelfSupportingResponse';
import PP002P82Noce2_0_SpanResponse from '../PP002P82Noce2_0/SpanResponse';
import PS001_GatewayResponse from '../PS001/GatewayResponse';
import PS001_GateGatewayResponse from '../PS001/GateGatewayResponse';
import PS001_NonSymmetricalGateResponse from '../PS001/NonSymmetricalGateResponse';
import PS001_SingleLeafGateResponse from '../PS001/SingleLeafGateResponse';
import PS001_GateTiltingResponse from '../PS001/GateTiltingResponse';
import PS001_GateSelfSupportingResponse from '../PS001/GateSelfSupportingResponse';
import PS001_SpanResponse from '../PS001/SpanResponse';
import PS002_GatewayResponse from '../PS002/GatewayResponse';
import PS002_GateGatewayResponse from '../PS002/GateGatewayResponse';
import PS002_NonSymmetricalGateResponse from '../PS002/NonSymmetricalGateResponse';
import PS002_SingleLeafGateResponse from '../PS002/SingleLeafGateResponse';
import PS002_GateTiltingResponse from '../PS002/GateTiltingResponse';
import PS002_GateSelfSupportingResponse from '../PS002/GateSelfSupportingResponse';
import PS002_SpanResponse from '../PS002/SpanResponse';
import PS003_GatewayResponse from '../PS003/GatewayResponse';
import PS003_GateGatewayResponse from '../PS003/GateGatewayResponse';
import PS003_NonSymmetricalGateResponse from '../PS003/NonSymmetricalGateResponse';
import PS003_SingleLeafGateResponse from '../PS003/SingleLeafGateResponse';
import PS003_GateTiltingResponse from '../PS003/GateTiltingResponse';
import PS003_GateSelfSupportingResponse from '../PS003/GateSelfSupportingResponse';
import PS003_SpanResponse from '../PS003/SpanResponse';
import PS004_GatewayResponse from '../PS004/GatewayResponse';
import PS004_GatewayWithMachineResponse from '../PS004/GatewayWithMachineResponse';
import PS004_GateGatewayResponse from '../PS004/GateGatewayResponse';
import PS004_NonSymmetricalGateResponse from '../PS004/NonSymmetricalGateResponse';
import PS004_SingleLeafGateResponse from '../PS004/SingleLeafGateResponse';
import PS004_GateTiltingResponse from '../PS004/GateTiltingResponse';
import PS004_FoldingGateResponse from '../PS004/FoldingGateResponse';
import PS004_SingleLeafFoldingGateResponse from '../PS004/SingleLeafFoldingGateResponse';
import PS004_GateSelfSupportingResponse from '../PS004/GateSelfSupportingResponse';
import PS004_SpanResponse from '../PS004/SpanResponse';
import PS004_2_GatewayResponse from '../PS004_2/GatewayResponse';
import PS004_2_GatewayWithMachineResponse from '../PS004_2/GatewayWithMachineResponse';
import PS004_2_GateGatewayResponse from '../PS004_2/GateGatewayResponse';
import PS004_2_NonSymmetricalGateResponse from '../PS004_2/NonSymmetricalGateResponse';
import PS004_2_SingleLeafGateResponse from '../PS004_2/SingleLeafGateResponse';
import PS004_2_GateTiltingResponse from '../PS004_2/GateTiltingResponse';
import PS004_2_FoldingGateResponse from '../PS004_2/FoldingGateResponse';
import PS004_2_SingleLeafFoldingGateResponse from '../PS004_2/SingleLeafFoldingGateResponse';
import PS004_2_GateSelfSupportingResponse from '../PS004_2/GateSelfSupportingResponse';
import PS004_2_SpanResponse from '../PS004_2/SpanResponse';
import PS005_SpanResponse from '../PS005/SpanResponse';
import STILE_GatewayResponse from '../Stile/GatewayResponse';
import STILE_GateGatewayResponse from '../Stile/GateGatewayResponse';
import STILE_NonSymmetricalGateResponse from '../Stile/NonSymmetricalGateResponse';
import STILE_SingleLeafGateResponse from '../Stile/SingleLeafGateResponse';
import STILE_GateTiltingResponse from '../Stile/GateTiltingResponse';
import STILE_GateSelfSupportingResponse from '../Stile/GateSelfSupportingResponse';
import PP002P82PINO1_GatewayResponse from '../PP002P82Pino/GatewayResponse';
import PP002P82PINO1_GateGatewayResponse from '../PP002P82Pino/GateGatewayResponse';
import PP002P82PINO1_NonSymmetricalGateResponse from '../PP002P82Pino/NonSymmetricalGateResponse';
import PP002P82PINO1_SingleLeafGateResponse from '../PP002P82Pino/SingleLeafGateResponse';
import PP002P82PINO1_GateTiltingResponse from '../PP002P82Pino/GateTiltingResponse';
import PP002P82PINO1_FoldingGateResponse from '../PP002P82Pino/FoldingGateResponse';
import PP002P82PINO1_SingleLeafFoldingGateResponse from '../PP002P82Pino/SingleLeafFoldingGateResponse';
import PP002P82PINO1_GateSelfSupportingResponse from '../PP002P82Pino/GateSelfSupportingResponse';
import PP002P82PINO1_SpanResponse from '../PP002P82Pino/SpanResponse';
import PP002P82PINO2_GatewayResponse from '../PP002P82Pino2/GatewayResponse';
import PP002P82PINO2_GateGatewayResponse from '../PP002P82Pino2/GateGatewayResponse';
import PP002P82PINO2_NonSymmetricalGateResponse from '../PP002P82Pino2/NonSymmetricalGateResponse';
import PP002P82PINO2_SingleLeafGateResponse from '../PP002P82Pino2/SingleLeafGateResponse';
import PP002P82PINO2_GateTiltingResponse from '../PP002P82Pino2/GateTiltingResponse';
import PP002P82PINO2_FoldingGateResponse from '../PP002P82Pino2/FoldingGateResponse';
import PP002P82PINO2_SingleLeafFoldingGateResponse from '../PP002P82Pino2/SingleLeafFoldingGateResponse';
import PP002P82PINO2_GateSelfSupportingResponse from '../PP002P82Pino2/GateSelfSupportingResponse';
import PP002P82PINO2_SpanResponse from '../PP002P82Pino2/SpanResponse';
import PP002P82PINO1_0_GatewayResponse from '../PP002P82Pino_0/GatewayResponse';
import PP002P82PINO1_0_GateGatewayResponse from '../PP002P82Pino_0/GateGatewayResponse';
import PP002P82PINO1_0_NonSymmetricalGateResponse from '../PP002P82Pino_0/NonSymmetricalGateResponse';
import PP002P82PINO1_0_SingleLeafGateResponse from '../PP002P82Pino_0/SingleLeafGateResponse';
import PP002P82PINO1_0_GateTiltingResponse from '../PP002P82Pino_0/GateTiltingResponse';
import PP002P82PINO1_0_FoldingGateResponse from '../PP002P82Pino_0/FoldingGateResponse';
import PP002P82PINO1_0_SingleLeafFoldingGateResponse from '../PP002P82Pino_0/SingleLeafFoldingGateResponse';
import PP002P82PINO1_0_GateSelfSupportingResponse from '../PP002P82Pino_0/GateSelfSupportingResponse';
import PP002P82PINO1_0_SpanResponse from '../PP002P82Pino_0/SpanResponse';
import PP002P82PINO2_0_GatewayResponse from '../PP002P82Pino2_0/GatewayResponse';
import PP002P82PINO2_0_GateGatewayResponse from '../PP002P82Pino2_0/GateGatewayResponse';
import PP002P82PINO2_0_NonSymmetricalGateResponse from '../PP002P82Pino2_0/NonSymmetricalGateResponse';
import PP002P82PINO2_0_SingleLeafGateResponse from '../PP002P82Pino2_0/SingleLeafGateResponse';
import PP002P82PINO2_0_GateTiltingResponse from '../PP002P82Pino2_0/GateTiltingResponse';
import PP002P82PINO2_0_FoldingGateResponse from '../PP002P82Pino2_0/FoldingGateResponse';
import PP002P82PINO2_0_SingleLeafFoldingGateResponse from '../PP002P82Pino2_0/SingleLeafFoldingGateResponse';
import PP002P82PINO2_0_GateSelfSupportingResponse from '../PP002P82Pino2_0/GateSelfSupportingResponse';
import PP002P82PINO2_0_SpanResponse from '../PP002P82Pino2_0/SpanResponse';
import Verticale_GatewayResponse from '../Verticale/GatewayResponse';
import Verticale_GateGatewayResponse from '../Verticale/GateGatewayResponse';
import Verticale_NonSymmetricalGateResponse from '../Verticale/NonSymmetricalGateResponse';
import Verticale_SingleLeafGateResponse from '../Verticale/SingleLeafGateResponse';
import Verticale_GateTiltingResponse from '../Verticale/GateTiltingResponse';
import Verticale_FoldingGateResponse from '../Verticale/FoldingGateResponse';
import Verticale_SingleLeafFoldingGateResponse from '../Verticale/SingleLeafFoldingGateResponse';
import Verticale_GateSelfSupportingResponse from '../Verticale/GateSelfSupportingResponse';
import Verticale_SpanResponse from '../Verticale/SpanResponse';
import Verticale_2_SpanResponse from '../Verticale_2/SpanResponse';
import Verticale_3_SpanResponse from '../Verticale_3/SpanResponse';
import Verticale_LM_SpanResponse from '../Verticale_LM/SpanResponse';
import Verticale_LM_GatewayResponse from '../Verticale_LM/GatewayResponse';
import Verticale_LM_GatewayAccessoriesResponse from '../Verticale_LM/GatewayAccessoriesResponse';
import Verticale_LM_GateSelfSupportingResponse from '../Verticale_LM/GateSelfSupportingResponse';
import Verticale_LM_GateTiltingResponse from '../Verticale_LM/GateTiltingResponse';
import Verticale_LM_GateTiltingAccessoriesResponse from '../Verticale_LM/GateTiltingAccessoriesResponse';
import Tvora1_GatewayResponse from '../Tvora1/GatewayResponse';
import Tvora1_GateTiltingResponse from '../Tvora1/GateTiltingResponse';
import Tvora1_GateSelfSupportingResponse from '../Tvora1/GateSelfSupportingResponse';
import Tvora2_GatewayResponse from '../Tvora2/GatewayResponse';
import Tvora2_GateTiltingResponse from '../Tvora2/GateTiltingResponse';
import Tvora2_GateSelfSupportingResponse from '../Tvora2/GateSelfSupportingResponse';
import Tvora3_GatewayResponse from '../Tvora3/GatewayResponse';
import Tvora3_GateTiltingResponse from '../Tvora3/GateTiltingResponse';
import Tvora3_GateSelfSupportingResponse from '../Tvora3/GateSelfSupportingResponse';
import Tvora4_GatewayResponse from '../Tvora4/GatewayResponse';
import Tvora4_GateTiltingResponse from '../Tvora4/GateTiltingResponse';
import Tvora4_GateSelfSupportingResponse from '../Tvora4/GateSelfSupportingResponse';
import Tvora5_GatewayResponse from '../Tvora5/GatewayResponse';
import Tvora5_GateGatewayResponse from '../Tvora5/GateGatewayResponse';
import Tvora5_GateTiltingResponse from '../Tvora5/GateTiltingResponse';
import Tvora5_GateSelfSupportingResponse from '../Tvora5/GateSelfSupportingResponse';
import Vms_GatewayResponse from '../Vms/GatewayResponse';
import Vms_GateTiltingResponse from '../Vms/GateTiltingResponse';
import Eco_GatewayResponse from '../Eco/GatewayResponse';
import Eco_GateTiltingResponse from '../Eco/GateTiltingResponse';
import Highway_GatewayResponse from '../Highway/GatewayResponse';
import Highway_GateTiltingResponse from '../Highway/GateTiltingResponse';
import PB155_SpanResponse from '../PB155/SpanResponse';
import PB155_GatewayResponse from '../PB155/GatewayResponse';
import PB155_GateTiltingResponse from '../PB155/GateTiltingResponse';
import PB155_FoldingGateResponse from '../PB155/FoldingGateResponse';
import PB155_SingleLeafGateResponse from '../PB155/SingleLeafGateResponse';
import PB155_SingleLeafFoldingGateResponse from '../PB155/SingleLeafFoldingGateResponse';
import PB155_GateSelfSupportingResponse from '../PB155/GateSelfSupportingResponse';
import PB155_GateGatewayResponse from '../PB155/GateGatewayResponse';
import PB155_NonSymmetricalGateResponse from '../PB155/NonSymmetricalGateResponse';
import SolarPanelPole_PoleSolarPanelReponse from '../PolesSolarPanels/PoleSolarPanelResponse';
import TableSolarPanel_TableSolarPanelsResponse from '../TableSolarPanel/TableSolarPanelsResponse';
import Carport_BasePolycarbonateResponse from '../Carport/BasePolycarbonateResponse';
import Carport_BasePlateResponse from '../Carport/BasePlateResponse';
import Carport_BaseSolarPanelsResponse from '../Carport/BaseSolarPanelsResponse';
import Carport_ExtensionPolycarbonateResponse from '../Carport/ExtensionPolycarbonateResponse';
import Carport_ExtensionPlateResponse from '../Carport/ExtensionPlateResponse';
import Carport_ExtensionSolarPanelsResponse from '../Carport/ExtensionSolarPanelsResponse';
import Ballast_Ballast_Response from '../Ballast/Ballast_Response';
import Ballast_Windchest_Response from '../Ballast/Windchest_Response';
import EntranceResponse from '../Eq/EntranceResponse';
/* eslint-enable camelcase */

/*
* Keep README.md update with any change of this logic
*/
const PdfResponseComponent = ({
  tag, response, values, hdg,
}) => {
  const components = {
    /* eslint-disable camelcase */
    PP002P82_GATEWAY: PP002P82_GatewayResponse,
    PP002P82_GATEGATEWAY: PP002P82_GateGatewayResponse,
    PP002P82_NONSYMMETRICALGATE: PP002P82_NonSymmetricalGateResponse,
    PP002P82_SINGLELEAFGATE: PP002P82_SingleLeafGateResponse,
    PP002P82_GATETILTING: PP002P82_GateTiltingResponse,
    PP002P82_FOLDINGGATE: PP002P82_FoldingGateResponse,
    PP002P82_SINGLELEAFFOLDINGGATE: PP002P82_SingleLeafFoldingGateResponse,
    PP002P82_GATESELFSUPPORTING: PP002P82_GateSelfSupportingResponse,
    PP002P82_SPAN: PP002P82_SpanResponse,
    PP002P82_0_GATEWAY: PP002P82_0_GatewayResponse,
    PP002P82_0_GATEGATEWAY: PP002P82_0_GateGatewayResponse,
    PP002P82_0_NONSYMMETRICALGATE: PP002P82_0_NonSymmetricalGateResponse,
    PP002P82_0_SINGLELEAFGATE: PP002P82_0_SingleLeafGateResponse,
    PP002P82_0_GATETILTING: PP002P82_0_GateTiltingResponse,
    PP002P82_0_FOLDINGGATE: PP002P82_0_FoldingGateResponse,
    PP002P82_0_SINGLELEAFFOLDINGGATE: PP002P82_0_SingleLeafFoldingGateResponse,
    PP002P82_0_GATESELFSUPPORTING: PP002P82_0_GateSelfSupportingResponse,
    PP002P82_0_SPAN: PP002P82_0_SpanResponse,
    PB001_GATEGATEWAY: PB001_GateGatewayResponse,
    PB001_GATEWAY: PB001_GatewayResponse,
    PB001_NONSYMMETRICALGATE: PB001_NonSymmetricalGateResponse,
    PB001_SINGLELEAFGATE: PB001_SingleLeafGateResponse,
    PB001_GATETILTING: PB001_GateTiltingResponse,
    PB001_FOLDINGGATE: PB001_FoldingGateResponse,
    PB001_SINGLELEAFFOLDINGGATE: PB001_SingleLeafFoldingGateResponse,
    PB001_GATESELFSUPPORTING: PB001_GateSelfSupportingResponse,
    PB001_SPAN: PB001_SpanResponse,
    PB003_SPAN: PB003_SpanResponse,
    PB003_GATEWAY: PB003_GatewayResponse,
    PB003_GATEGATEWAY: PB003_GateGatewayResponse,
    PB003_NONSYMMETRICALGATE: PB003_NonSymmetricalGateResponse,
    PB003_SINGLELEAFGATE: PB003_SingleLeafGateResponse,
    PB003_GATETILTING: PB003_GateTiltingResponse,
    PB003_FOLDINGGATE: PB003_FoldingGateResponse,
    PB003_SINGLELEAFFOLDINGGATE: PB003_SingleLeafFoldingGateResponse,
    PB003_GATESELFSUPPORTING: PB003_GateSelfSupportingResponse,
    PP001C_GATEWAY: PP001C_GatewayResponse,
    PP001C_GATEGATEWAY: PP001C_GateGatewayResponse,
    PP001C_NONSYMMETRICALGATE: PP001C_NonSymmetricalGateResponse,
    PP001C_SINGLELEAFGATE: PP001C_SingleLeafGateResponse,
    PP001C_GATETILTING: PP001C_GateTiltingResponse,
    PP001C_GATESELFSUPPORTING: PP001C_GateSelfSupportingResponse,
    PP001C_SPAN: PP001C_SpanResponse,
    ThreeD4_GATEWAY: ThreeD4_GatewayResponse,
    ThreeD4_GATEGATEWAY: ThreeD4_GateGatewayResponse,
    ThreeD4_NONSYMMETRICALGATE: ThreeD4_NonSymmetricalGateResponse,
    ThreeD4_SINGLELEAFGATE: ThreeD4_SingleLeafGateResponse,
    ThreeD4_GATETILTING: ThreeD4_GateTiltingResponse,
    ThreeD4_GATESELFSUPPORTING: ThreeD4_GateSelfSupportingResponse,
    ThreeD5_GATEWAY: ThreeD5_GatewayResponse,
    ThreeD5_GATEGATEWAY: ThreeD5_GateGatewayResponse,
    ThreeD5_NONSYMMETRICALGATE: ThreeD5_NonSymmetricalGateResponse,
    ThreeD5_SINGLELEAFGATE: ThreeD5_SingleLeafGateResponse,
    ThreeD5_GATETILTING: ThreeD5_GateTiltingResponse,
    ThreeD5_GATESELFSUPPORTING: ThreeD5_GateSelfSupportingResponse,
    TwoD_GATEWAY: TwoD_GatewayResponse,
    TwoD_GATEGATEWAY: TwoD_GateGatewayResponse,
    TwoD_NONSYMMETRICALGATE: TwoD_NonSymmetricalGateResponse,
    TwoD_SINGLELEAFGATE: TwoD_SingleLeafGateResponse,
    TwoD_GATETILTING: TwoD_GateTiltingResponse,
    TwoD_FOLDINGGATE: TwoD_FoldingGateResponse,
    TwoD_SINGLELEAFFOLDINGGATE: TwoD_SingleLeafFoldingGateResponse,
    TwoD_GATESELFSUPPORTING: TwoD_GateSelfSupportingResponse,
    TwoDS_GATEWAY: TwoDS_GatewayResponse,
    TwoDS_GATEGATEWAY: TwoDS_GateGatewayResponse,
    TwoDS_NONSYMMETRICALGATE: TwoDS_NonSymmetricalGateResponse,
    TwoDS_SINGLELEAFGATE: TwoDS_SingleLeafGateResponse,
    TwoDS_GATETILTING: TwoDS_GateTiltingResponse,
    TwoDS_FOLDINGGATE: TwoDS_FoldingGateResponse,
    TwoDS_SINGLELEAFFOLDINGGATE: TwoDS_SingleLeafFoldingGateResponse,
    TwoDS_GATESELFSUPPORTING: TwoDS_GateSelfSupportingResponse,
    PP001CC_GATEWAY: PP001CC_GatewayResponse,
    PP001CC_GATEGATEWAY: PP001CC_GateGatewayResponse,
    PP001CC_NONSYMMETRICALGATE: PP001CC_NonSymmetricalGateResponse,
    PP001CC_SINGLELEAFGATE: PP001CC_SingleLeafGateResponse,
    PP001CC_GATETILTING: PP001CC_GateTiltingResponse,
    PP001CC_GATESELFSUPPORTING: PP001CC_GateSelfSupportingResponse,
    PP001CC_SPAN: PP001CC_SpanResponse,
    PP001W_GATEWAY: PP001W_GatewayResponse,
    PP001W_GATEGATEWAY: PP001W_GateGatewayResponse,
    PP001W_NONSYMMETRICALGATE: PP001W_NonSymmetricalGateResponse,
    PP001W_SINGLELEAFGATE: PP001W_SingleLeafGateResponse,
    PP001W_GATETILTING: PP001W_GateTiltingResponse,
    PP001W_GATESELFSUPPORTING: PP001W_GateSelfSupportingResponse,
    PP001W_SPAN: PP001W_SpanResponse,
    PP001N_GATEWAY: PP001N_GatewayResponse,
    PP001N_GATEGATEWAY: PP001N_GateGatewayResponse,
    PP001N_NONSYMMETRICALGATE: PP001N_NonSymmetricalGateResponse,
    PP001N_SINGLELEAFGATE: PP001N_SingleLeafGateResponse,
    PP001N_GATETILTING: PP001N_GateTiltingResponse,
    PP001N_GATESELFSUPPORTING: PP001N_GateSelfSupportingResponse,
    PP001N_SPAN: PP001N_SpanResponse,
    PP002P82COLORE1_GATEWAY: PP002P82COLORE1_GatewayResponse,
    PP002P82COLORE1_GATEGATEWAY: PP002P82COLORE1_GateGatewayResponse,
    PP002P82COLORE1_NONSYMMETRICALGATE: PP002P82COLORE1_NonSymmetricalGateResponse,
    PP002P82COLORE1_SINGLELEAFGATE: PP002P82COLORE1_SingleLeafGateResponse,
    PP002P82COLORE1_GATETILTING: PP002P82COLORE1_GateTiltingResponse,
    PP002P82COLORE1_FOLDINGGATE: PP002P82COLORE1_FoldingGateResponse,
    PP002P82COLORE1_SINGLELEAFFOLDINGGATE: PP002P82COLORE1_SingleLeafFoldingGateResponse,
    PP002P82COLORE1_GATESELFSUPPORTING: PP002P82COLORE1_GateSelfSupportingResponse,
    PP002P82COLORE1_SPAN: PP002P82COLORE1_SpanResponse,
    PP002P82COLORE2_GATEWAY: PP002P82COLORE2_GatewayResponse,
    PP002P82COLORE2_GATEGATEWAY: PP002P82COLORE2_GateGatewayResponse,
    PP002P82COLORE2_NONSYMMETRICALGATE: PP002P82COLORE2_NonSymmetricalGateResponse,
    PP002P82COLORE2_SINGLELEAFGATE: PP002P82COLORE2_SingleLeafGateResponse,
    PP002P82COLORE2_GATETILTING: PP002P82COLORE2_GateTiltingResponse,
    PP002P82COLORE2_FOLDINGGATE: PP002P82COLORE2_FoldingGateResponse,
    PP002P82COLORE2_SINGLELEAFFOLDINGGATE: PP002P82COLORE2_SingleLeafFoldingGateResponse,
    PP002P82COLORE2_GATESELFSUPPORTING: PP002P82COLORE2_GateSelfSupportingResponse,
    PP002P82COLORE2_SPAN: PP002P82COLORE2_SpanResponse,
    PP002P82COLORE1_0_GATEWAY: PP002P82COLORE1_0_GatewayResponse,
    PP002P82COLORE1_0_GATEGATEWAY: PP002P82COLORE1_0_GateGatewayResponse,
    PP002P82COLORE1_0_NONSYMMETRICALGATE: PP002P82COLORE1_0_NonSymmetricalGateResponse,
    PP002P82COLORE1_0_SINGLELEAFGATE: PP002P82COLORE1_0_SingleLeafGateResponse,
    PP002P82COLORE1_0_GATETILTING: PP002P82COLORE1_0_GateTiltingResponse,
    PP002P82COLORE1_0_FOLDINGGATE: PP002P82COLORE1_0_FoldingGateResponse,
    PP002P82COLORE1_0_SINGLELEAFFOLDINGGATE: PP002P82COLORE1_0_SingleLeafFoldingGateResponse,
    PP002P82COLORE1_0_GATESELFSUPPORTING: PP002P82COLORE1_0_GateSelfSupportingResponse,
    PP002P82COLORE1_0_SPAN: PP002P82COLORE1_0_SpanResponse,
    PP002P82COLORE2_0_GATEWAY: PP002P82COLORE2_0_GatewayResponse,
    PP002P82COLORE2_0_GATEGATEWAY: PP002P82COLORE2_0_GateGatewayResponse,
    PP002P82COLORE2_0_NONSYMMETRICALGATE: PP002P82COLORE2_0_NonSymmetricalGateResponse,
    PP002P82COLORE2_0_SINGLELEAFGATE: PP002P82COLORE2_0_SingleLeafGateResponse,
    PP002P82COLORE2_0_GATETILTING: PP002P82COLORE2_0_GateTiltingResponse,
    PP002P82COLORE2_0_FOLDINGGATE: PP002P82COLORE2_0_FoldingGateResponse,
    PP002P82COLORE2_0_SINGLELEAFFOLDINGGATE: PP002P82COLORE2_0_SingleLeafFoldingGateResponse,
    PP002P82COLORE2_0_GATESELFSUPPORTING: PP002P82COLORE2_0_GateSelfSupportingResponse,
    PP002P82COLORE2_0_SPAN: PP002P82COLORE2_0_SpanResponse,
    PP002P102_GATEWAY: PP002P102_GatewayResponse,
    PP002P102_GATEGATEWAY: PP002P102_GateGatewayResponse,
    PP002P102_NONSYMMETRICALGATE: PP002P102_NonSymmetricalGateResponse,
    PP002P102_SINGLELEAFGATE: PP002P102_SingleLeafGateResponse,
    PP002P102_GATETILTING: PP002P102_GateTiltingResponse,
    PP002P102_FOLDINGGATE: PP002P102_FoldingGateResponse,
    PP002P102_SINGLELEAFFOLDINGGATE: PP002P102_SingleLeafFoldingGateResponse,
    PP002P102_GATESELFSUPPORTING: PP002P102_GateSelfSupportingResponse,
    PP002P102_SPAN: PP002P102_SpanResponse,
    PP002P102_0_GATEWAY: PP002P102_0_GatewayResponse,
    PP002P102_0_GATEGATEWAY: PP002P102_0_GateGatewayResponse,
    PP002P102_0_NONSYMMETRICALGATE: PP002P102_0_NonSymmetricalGateResponse,
    PP002P102_0_SINGLELEAFGATE: PP002P102_0_SingleLeafGateResponse,
    PP002P102_0_GATETILTING: PP002P102_0_GateTiltingResponse,
    PP002P102_0_FOLDINGGATE: PP002P102_0_FoldingGateResponse,
    PP002P102_0_SINGLELEAFFOLDINGGATE: PP002P102_0_SingleLeafFoldingGateResponse,
    PP002P102_0_GATESELFSUPPORTING: PP002P102_0_GateSelfSupportingResponse,
    PP002P102_0_SPAN: PP002P102_0_SpanResponse,
    PP001P46_GATEWAY: PP001P46_GatewayResponse,
    PP001P46_GATEGATEWAY: PP001P46_GateGatewayResponse,
    PP001P46_NONSYMMETRICALGATE: PP001P46_NonSymmetricalGateResponse,
    PP001P46_SINGLELEAFGATE: PP001P46_SingleLeafGateResponse,
    PP001P46_GATETILTING: PP001P46_GateTiltingResponse,
    PP001P46_GATESELFSUPPORTING: PP001P46_GateSelfSupportingResponse,
    PP001P46_SPAN: PP001P46_SpanResponse,
    PP002P64_GATEWAY: PP002P64_GatewayResponse,
    PP002P64_GATEGATEWAY: PP002P64_GateGatewayResponse,
    PP002P64_NONSYMMETRICALGATE: PP002P64_NonSymmetricalGateResponse,
    PP002P64_SINGLELEAFGATE: PP002P64_SingleLeafGateResponse,
    PP002P64_GATETILTING: PP002P64_GateTiltingResponse,
    PP002P64_FOLDINGGATE: PP002P64_FoldingGateResponse,
    PP002P64_SINGLELEAFFOLDINGGATE: PP002P64_SingleLeafFoldingGateResponse,
    PP002P64_GATESELFSUPPORTING: PP002P64_GateSelfSupportingResponse,
    PP002P64_SPAN: PP002P64_SpanResponse,
    P302_SPAN: P302_SpanResponse,
    P302_GATEWAY: P302_GatewayResponse,
    P302_GATEGATEWAY: P302_GateGatewayResponse,
    P302_NONSYMMETRICALGATE: P302_NonSymmetricalGateResponse,
    P302_SINGLELEAFGATE: P302_SingleLeafGateResponse,
    P302_GATETILTING: P302_GateTiltingResponse,
    P302_FOLDINGGATE: P302_FoldingGateResponse,
    P302_SINGLELEAFFOLDINGGATE: P302_SingleLeafFoldingGateResponse,
    P302_GATESELFSUPPORTING: P302_GateSelfSupportingResponse,
    P302PB002_SPAN: P302PB002_SpanResponse,
    P302PB002_GATEWAY: P302PB002_GatewayResponse,
    P302PB002_GATEGATEWAY: P302PB002_GateGatewayResponse,
    P302PB002_NONSYMMETRICALGATE: P302PB002_NonSymmetricalGateResponse,
    P302PB002_SINGLELEAFGATE: P302PB002_SingleLeafGateResponse,
    P302PB002_GATETILTING: P302PB002_GateTiltingResponse,
    P302PB002_FOLDINGGATE: P302PB002_FoldingGateResponse,
    P302PB002_SINGLELEAFFOLDINGGATE: P302PB002_SingleLeafFoldingGateResponse,
    P302PB002_GATESELFSUPPORTING: P302PB002_GateSelfSupportingResponse,
    P302PS005_SPAN: P302PS005_SpanResponse,
    P302PS005_GATEWAY: P302PS005_GatewayResponse,
    P302PS005_GATEGATEWAY: P302PS005_GateGatewayResponse,
    P302PS005_NONSYMMETRICALGATE: P302PS005_NonSymmetricalGateResponse,
    P302PS005_SINGLELEAFGATE: P302PS005_SingleLeafGateResponse,
    P302PS005_GATETILTING: P302PS005_GateTiltingResponse,
    P302PS005_FOLDINGGATE: P302PS005_FoldingGateResponse,
    P302PS005_SINGLELEAFFOLDINGGATE: P302PS005_SingleLeafFoldingGateResponse,
    P302PS005_GATESELFSUPPORTING: P302PS005_GateSelfSupportingResponse,
    PP002P_SPAN: PP002P_SpanResponse,
    PP002P_GATEWAY: PP002P_GatewayResponse,
    PP002P_GATEGATEWAY: PP002P_GateGatewayResponse,
    PP002P_NONSYMMETRICALGATE: PP002P_NonSymmetricalGateResponse,
    PP002P_SINGLELEAFGATE: PP002P_SingleLeafGateResponse,
    PP002P_GATETILTING: PP002P_GateTiltingResponse,
    PP002P_FOLDINGGATE: PP002P_FoldingGateResponse,
    PP002P_SINGLELEAFFOLDINGGATE: PP002P_SingleLeafFoldingGateResponse,
    PP002P_GATESELFSUPPORTING: PP002P_GateSelfSupportingResponse,
    PP002_SPAN: PP002_SpanResponse,
    PP002_GATEWAY: PP002_GatewayResponse,
    PP002_GATEGATEWAY: PP002_GateGatewayResponse,
    PP002_NONSYMMETRICALGATE: PP002_NonSymmetricalGateResponse,
    PP002_SINGLELEAFGATE: PP002_SingleLeafGateResponse,
    PP002_GATETILTING: PP002_GateTiltingResponse,
    PP002_FOLDINGGATE: PP002_FoldingGateResponse,
    PP002_SINGLELEAFFOLDINGGATE: PP002_SingleLeafFoldingGateResponse,
    PP002_GATESELFSUPPORTING: PP002_GateSelfSupportingResponse,
    PP002DIVERSO_GATEWAY: PP002Diverso_GatewayResponse,
    PP002DIVERSO_GATEGATEWAY: PP002Diverso_GateGatewayResponse,
    PP002DIVERSO_NONSYMMETRICALGATE: PP002Diverso_NonSymmetricalGateResponse,
    PP002DIVERSO_SINGLELEAFGATE: PP002Diverso_SingleLeafGateResponse,
    PP002DIVERSO_GATETILTING: PP002Diverso_GateTiltingResponse,
    PP002DIVERSO_FOLDINGGATE: PP002Diverso_FoldingGateResponse,
    PP002DIVERSO_SINGLELEAFFOLDINGGATE: PP002Diverso_SingleLeafFoldingGateResponse,
    PP002DIVERSO_GATESELFSUPPORTING: PP002Diverso_GateSelfSupportingResponse,
    PP002DIVERSO_SPAN: PP002Diverso_SpanResponse,
    PP002DIVERSO1_GATEWAY: PP002Diverso1_GatewayResponse,
    PP002DIVERSO1_GATEGATEWAY: PP002Diverso1_GateGatewayResponse,
    PP002DIVERSO1_NONSYMMETRICALGATE: PP002Diverso1_NonSymmetricalGateResponse,
    PP002DIVERSO1_SINGLELEAFGATE: PP002Diverso1_SingleLeafGateResponse,
    PP002DIVERSO1_GATETILTING: PP002Diverso1_GateTiltingResponse,
    PP002DIVERSO1_FOLDINGGATE: PP002Diverso1_FoldingGateResponse,
    PP002DIVERSO1_SINGLELEAFFOLDINGGATE: PP002Diverso1_SingleLeafFoldingGateResponse,
    PP002DIVERSO1_GATESELFSUPPORTING: PP002Diverso1_GateSelfSupportingResponse,
    PP002DIVERSO1_SPAN: PP002Diverso1_SpanResponse,
    PP002DIVERSO2_GATEWAY: PP002Diverso2_GatewayResponse,
    PP002DIVERSO2_GATEGATEWAY: PP002Diverso2_GateGatewayResponse,
    PP002DIVERSO2_NONSYMMETRICALGATE: PP002Diverso2_NonSymmetricalGateResponse,
    PP002DIVERSO2_SINGLELEAFGATE: PP002Diverso2_SingleLeafGateResponse,
    PP002DIVERSO2_GATETILTING: PP002Diverso2_GateTiltingResponse,
    PP002DIVERSO2_FOLDINGGATE: PP002Diverso2_FoldingGateResponse,
    PP002DIVERSO2_SINGLELEAFFOLDINGGATE: PP002Diverso2_SingleLeafFoldingGateResponse,
    PP002DIVERSO2_GATESELFSUPPORTING: PP002Diverso2_GateSelfSupportingResponse,
    PP002DIVERSO2_SPAN: PP002Diverso2_SpanResponse,
    PP002DIVERSO3_GATEWAY: PP002Diverso3_GatewayResponse,
    PP002DIVERSO3_GATEGATEWAY: PP002Diverso3_GateGatewayResponse,
    PP002DIVERSO3_NONSYMMETRICALGATE: PP002Diverso3_NonSymmetricalGateResponse,
    PP002DIVERSO3_SINGLELEAFGATE: PP002Diverso3_SingleLeafGateResponse,
    PP002DIVERSO3_GATETILTING: PP002Diverso3_GateTiltingResponse,
    PP002DIVERSO3_FOLDINGGATE: PP002Diverso3_FoldingGateResponse,
    PP002DIVERSO3_SINGLELEAFFOLDINGGATE: PP002Diverso3_SingleLeafFoldingGateResponse,
    PP002DIVERSO3_GATESELFSUPPORTING: PP002Diverso3_GateSelfSupportingResponse,
    PP002DIVERSO3_SPAN: PP002Diverso3_SpanResponse,
    PP002P82MIX_GATEWAY: PP002P82Mix_GatewayResponse,
    PP002P82MIX_GATEGATEWAY: PP002P82Mix_GateGatewayResponse,
    PP002P82MIX_NONSYMMETRICALGATE: PP002P82Mix_NonSymmetricalGateResponse,
    PP002P82MIX_SINGLELEAFGATE: PP002P82Mix_SingleLeafGateResponse,
    PP002P82MIX_GATETILTING: PP002P82Mix_GateTiltingResponse,
    PP002P82MIX_FOLDINGGATE: PP002P82Mix_FoldingGateResponse,
    PP002P82MIX_SINGLELEAFFOLDINGGATE: PP002P82Mix_SingleLeafFoldingGateResponse,
    PP002P82MIX_GATESELFSUPPORTING: PP002P82Mix_GateSelfSupportingResponse,
    PP002P82MIX_SPAN: PP002P82Mix_SpanResponse,
    PP002P82NOCE1_GATEWAY: PP002P82Noce1_GatewayResponse,
    PP002P82NOCE1_GATEGATEWAY: PP002P82Noce1_GateGatewayResponse,
    PP002P82NOCE1_NONSYMMETRICALGATE: PP002P82Noce1_NonSymmetricalGateResponse,
    PP002P82NOCE1_SINGLELEAFGATE: PP001P82Noce1_SingleLeafGateResponse,
    PP002P82NOCE1_GATETILTING: PP002P82Noce1_GateTiltingResponse,
    PP002P82NOCE1_FOLDINGGATE: PP002P82Noce1_FoldingGateResponse,
    PP002P82NOCE1_SINGLELEAFFOLDINGGATE: PP002P82Noce1_SingleLeafFoldingGateResponse,
    PP002P82NOCE1_GATESELFSUPPORTING: PP002P82Noce1_GateSelfSupportingResponse,
    PP002P82NOCE1_SPAN: PP002P82Noce1_SpanResponse,
    PP002P82NOCE2_GATEWAY: PP002P82Noce2_GatewayResponse,
    PP002P82NOCE2_GATEGATEWAY: PP002P82Noce2_GateGatewayResponse,
    PP002P82NOCE2_NONSYMMETRICALGATE: PP002P82Noce2_NonSymmetricalGateResponse,
    PP002P82NOCE2_SINGLELEAFGATE: PP002P82Noce2_SingleLeafGateResponse,
    PP002P82NOCE2_GATETILTING: PP002P82Noce2_GateTiltingResponse,
    PP002P82NOCE2_FOLDINGGATE: PP002P82Noce2_FoldingGateResponse,
    PP002P82NOCE2_SINGLELEAFFOLDINGGATE: PP002P82Noce2_SingleLeafFoldingGateResponse,
    PP002P82NOCE2_GATESELFSUPPORTING: PP002P82Noce2_GateSelfSupportingResponse,
    PP002P82NOCE2_SPAN: PP002P82Noce2_SpanResponse,
    PP002P82NOCE1_0_GATEWAY: PP002P82Noce1_0_GatewayResponse,
    PP002P82NOCE1_0_GATEGATEWAY: PP002P82Noce1_0_GateGatewayResponse,
    PP002P82NOCE1_0_NONSYMMETRICALGATE: PP002P82Noce1_0_NonSymmetricalGateResponse,
    PP002P82NOCE1_0_SINGLELEAFGATE: PP001P82Noce1_0_SingleLeafGateResponse,
    PP002P82NOCE1_0_GATETILTING: PP002P82Noce1_0_GateTiltingResponse,
    PP002P82NOCE1_0_FOLDINGGATE: PP002P82Noce1_0_FoldingGateResponse,
    PP002P82NOCE1_0_SINGLELEAFFOLDINGGATE: PP002P82Noce1_0_SingleLeafFoldingGateResponse,
    PP002P82NOCE1_0_GATESELFSUPPORTING: PP002P82Noce1_0_GateSelfSupportingResponse,
    PP002P82NOCE1_0_SPAN: PP002P82Noce1_0_SpanResponse,
    PP002P82NOCE2_0_GATEWAY: PP002P82Noce2_0_GatewayResponse,
    PP002P82NOCE2_0_GATEGATEWAY: PP002P82Noce2_0_GateGatewayResponse,
    PP002P82NOCE2_0_NONSYMMETRICALGATE: PP002P82Noce2_0_NonSymmetricalGateResponse,
    PP002P82NOCE2_0_SINGLELEAFGATE: PP002P82Noce2_0_SingleLeafGateResponse,
    PP002P82NOCE2_0_GATETILTING: PP002P82Noce2_0_GateTiltingResponse,
    PP002P82NOCE2_0_FOLDINGGATE: PP002P82Noce2_0_FoldingGateResponse,
    PP002P82NOCE2_0_SINGLELEAFFOLDINGGATE: PP002P82Noce2_0_SingleLeafFoldingGateResponse,
    PP002P82NOCE2_0_GATESELFSUPPORTING: PP002P82Noce2_0_GateSelfSupportingResponse,
    PP002P82NOCE2_0_SPAN: PP002P82Noce2_0_SpanResponse,
    PS001_GATEWAY: PS001_GatewayResponse,
    PS001_GATEGATEWAY: PS001_GateGatewayResponse,
    PS001_NONSYMMETRICALGATE: PS001_NonSymmetricalGateResponse,
    PS001_SINGLELEAFGATE: PS001_SingleLeafGateResponse,
    PS001_GATETILTING: PS001_GateTiltingResponse,
    PS001_GATESELFSUPPORTING: PS001_GateSelfSupportingResponse,
    PS001_SPAN: PS001_SpanResponse,
    PS002_GATEWAY: PS002_GatewayResponse,
    PS002_GATEGATEWAY: PS002_GateGatewayResponse,
    PS002_NONSYMMETRICALGATE: PS002_NonSymmetricalGateResponse,
    PS002_SINGLELEAFGATE: PS002_SingleLeafGateResponse,
    PS002_GATETILTING: PS002_GateTiltingResponse,
    PS002_GATESELFSUPPORTING: PS002_GateSelfSupportingResponse,
    PS002_SPAN: PS002_SpanResponse,
    PS003_GATEWAY: PS003_GatewayResponse,
    PS003_GATEGATEWAY: PS003_GateGatewayResponse,
    PS003_NONSYMMETRICALGATE: PS003_NonSymmetricalGateResponse,
    PS003_SINGLELEAFGATE: PS003_SingleLeafGateResponse,
    PS003_GATETILTING: PS003_GateTiltingResponse,
    PS003_GATESELFSUPPORTING: PS003_GateSelfSupportingResponse,
    PS003_SPAN: PS003_SpanResponse,
    PS004_GATEWAY: PS004_GatewayResponse,
    PS004_GATEWAYWITHMACHINE: PS004_GatewayWithMachineResponse,
    PS004_GATEGATEWAY: PS004_GateGatewayResponse,
    PS004_NONSYMMETRICALGATE: PS004_NonSymmetricalGateResponse,
    PS004_SINGLELEAFGATE: PS004_SingleLeafGateResponse,
    PS004_GATETILTING: PS004_GateTiltingResponse,
    PS004_FOLDINGGATE: PS004_FoldingGateResponse,
    PS004_SINGLELEAFFOLDINGGATE: PS004_SingleLeafFoldingGateResponse,
    PS004_GATESELFSUPPORTING: PS004_GateSelfSupportingResponse,
    PS004_SPAN: PS004_SpanResponse,
    PS004_2_GATEWAY: PS004_2_GatewayResponse,
    PS004_2_GATEWAYWITHMACHINE: PS004_2_GatewayWithMachineResponse,
    PS004_2_GATEGATEWAY: PS004_2_GateGatewayResponse,
    PS004_2_NONSYMMETRICALGATE: PS004_2_NonSymmetricalGateResponse,
    PS004_2_SINGLELEAFGATE: PS004_2_SingleLeafGateResponse,
    PS004_2_GATETILTING: PS004_2_GateTiltingResponse,
    PS004_2_FOLDINGGATE: PS004_2_FoldingGateResponse,
    PS004_2_SINGLELEAFFOLDINGGATE: PS004_2_SingleLeafFoldingGateResponse,
    PS004_2_GATESELFSUPPORTING: PS004_2_GateSelfSupportingResponse,
    PS004_2_SPAN: PS004_2_SpanResponse,
    PS005_SPAN: PS005_SpanResponse,
    STILE_GATEWAY: STILE_GatewayResponse,
    STILE_GATEGATEWAY: STILE_GateGatewayResponse,
    STILE_NONSYMMETRICALGATE: STILE_NonSymmetricalGateResponse,
    STILE_SINGLELEAFGATE: STILE_SingleLeafGateResponse,
    STILE_GATETILTING: STILE_GateTiltingResponse,
    STILE_GATESELFSUPPORTING: STILE_GateSelfSupportingResponse,
    PP002P82PINO1_GATEWAY: PP002P82PINO1_GatewayResponse,
    PP002P82PINO1_GATEGATEWAY: PP002P82PINO1_GateGatewayResponse,
    PP002P82PINO1_NONSYMMETRICALGATE: PP002P82PINO1_NonSymmetricalGateResponse,
    PP002P82PINO1_SINGLELEAFGATE: PP002P82PINO1_SingleLeafGateResponse,
    PP002P82PINO1_GATETILTING: PP002P82PINO1_GateTiltingResponse,
    PP002P82PINO1_FOLDINGGATE: PP002P82PINO1_FoldingGateResponse,
    PP002P82PINO1_SINGLELEAFFOLDINGGATE: PP002P82PINO1_SingleLeafFoldingGateResponse,
    PP002P82PINO1_GATESELFSUPPORTING: PP002P82PINO1_GateSelfSupportingResponse,
    PP002P82PINO1_SPAN: PP002P82PINO1_SpanResponse,
    PP002P82PINO2_GATEWAY: PP002P82PINO2_GatewayResponse,
    PP002P82PINO2_GATEGATEWAY: PP002P82PINO2_GateGatewayResponse,
    PP002P82PINO2_NONSYMMETRICALGATE: PP002P82PINO2_NonSymmetricalGateResponse,
    PP002P82PINO2_SINGLELEAFGATE: PP002P82PINO2_SingleLeafGateResponse,
    PP002P82PINO2_GATETILTING: PP002P82PINO2_GateTiltingResponse,
    PP002P82PINO2_FOLDINGGATE: PP002P82PINO2_FoldingGateResponse,
    PP002P82PINO2_SINGLELEAFFOLDINGGATE: PP002P82PINO2_SingleLeafFoldingGateResponse,
    PP002P82PINO2_GATESELFSUPPORTING: PP002P82PINO2_GateSelfSupportingResponse,
    PP002P82PINO2_SPAN: PP002P82PINO2_SpanResponse,
    PP002P82PINO1_0_GATEWAY: PP002P82PINO1_0_GatewayResponse,
    PP002P82PINO1_0_GATEGATEWAY: PP002P82PINO1_0_GateGatewayResponse,
    PP002P82PINO1_0_NONSYMMETRICALGATE: PP002P82PINO1_0_NonSymmetricalGateResponse,
    PP002P82PINO1_0_SINGLELEAFGATE: PP002P82PINO1_0_SingleLeafGateResponse,
    PP002P82PINO1_0_GATETILTING: PP002P82PINO1_0_GateTiltingResponse,
    PP002P82PINO1_0_FOLDINGGATE: PP002P82PINO1_0_FoldingGateResponse,
    PP002P82PINO1_0_SINGLELEAFFOLDINGGATE: PP002P82PINO1_0_SingleLeafFoldingGateResponse,
    PP002P82PINO1_0_GATESELFSUPPORTING: PP002P82PINO1_0_GateSelfSupportingResponse,
    PP002P82PINO1_0_SPAN: PP002P82PINO1_0_SpanResponse,
    PP002P82PINO2_0_GATEWAY: PP002P82PINO2_0_GatewayResponse,
    PP002P82PINO2_0_GATEGATEWAY: PP002P82PINO2_0_GateGatewayResponse,
    PP002P82PINO2_0_NONSYMMETRICALGATE: PP002P82PINO2_0_NonSymmetricalGateResponse,
    PP002P82PINO2_0_SINGLELEAFGATE: PP002P82PINO2_0_SingleLeafGateResponse,
    PP002P82PINO2_0_GATETILTING: PP002P82PINO2_0_GateTiltingResponse,
    PP002P82PINO2_0_FOLDINGGATE: PP002P82PINO2_0_FoldingGateResponse,
    PP002P82PINO2_0_SINGLELEAFFOLDINGGATE: PP002P82PINO2_0_SingleLeafFoldingGateResponse,
    PP002P82PINO2_0_GATESELFSUPPORTING: PP002P82PINO2_0_GateSelfSupportingResponse,
    PP002P82PINO2_0_SPAN: PP002P82PINO2_0_SpanResponse,
    PB155_GATEWAY: PB155_GatewayResponse,
    PB155_GATEGATEWAY: PB155_GateGatewayResponse,
    PB155_NONSYMMETRICALGATE: PB155_NonSymmetricalGateResponse,
    PB155_SINGLELEAFGATE: PB155_SingleLeafGateResponse,
    PB155_GATETILTING: PB155_GateTiltingResponse,
    PB155_FOLDINGGATE: PB155_FoldingGateResponse,
    PB155_SINGLELEAFFOLDINGGATE: PB155_SingleLeafFoldingGateResponse,
    PB155_GATESELFSUPPORTING: PB155_GateSelfSupportingResponse,
    PB155_SPAN: PB155_SpanResponse,
    VERTICALE_SPAN: Verticale_SpanResponse,
    VERTICALE_GATEWAY: Verticale_GatewayResponse,
    VERTICALE_GATEGATEWAY: Verticale_GateGatewayResponse,
    VERTICALE_NONSYMMETRICALGATE: Verticale_NonSymmetricalGateResponse,
    VERTICALE_SINGLELEAFGATE: Verticale_SingleLeafGateResponse,
    VERTICALE_GATETILTING: Verticale_GateTiltingResponse,
    VERTICALE_FOLDINGGATE: Verticale_FoldingGateResponse,
    VERTICALE_SINGLELEAFFOLDINGGATE: Verticale_SingleLeafFoldingGateResponse,
    VERTICALE_GATESELFSUPPORTING: Verticale_GateSelfSupportingResponse,
    VERTICALE_2_SPAN: Verticale_2_SpanResponse,
    VERTICALE_3_SPAN: Verticale_3_SpanResponse,
    VERTICALE_LM_SPAN: Verticale_LM_SpanResponse,
    VERTICALE_LM_GATEWAY: Verticale_LM_GatewayResponse,
    VERTICALE_LM_GATEWAYACCESSORIES: Verticale_LM_GatewayAccessoriesResponse,
    VERTICALE_LM_GATESELFSUPPORTING: Verticale_LM_GateSelfSupportingResponse,
    VERTICALE_LM_GATETILTING: Verticale_LM_GateTiltingResponse,
    VERTICALE_LM_GATETILTINGACCESSORIES: Verticale_LM_GateTiltingAccessoriesResponse,
    TVORA1_GATEWAY: Tvora1_GatewayResponse,
    TVORA1_GATETILTING: Tvora1_GateTiltingResponse,
    TVORA1_GATESELFSUPPORTING: Tvora1_GateSelfSupportingResponse,
    TVORA2_GATEWAY: Tvora2_GatewayResponse,
    TVORA2_GATETILTING: Tvora2_GateTiltingResponse,
    TVORA2_GATESELFSUPPORTING: Tvora2_GateSelfSupportingResponse,
    TVORA3_GATEWAY: Tvora3_GatewayResponse,
    TVORA3_GATETILTING: Tvora3_GateTiltingResponse,
    TVORA3_GATESELFSUPPORTING: Tvora3_GateSelfSupportingResponse,
    TVORA4_GATEWAY: Tvora4_GatewayResponse,
    TVORA4_GATETILTING: Tvora4_GateTiltingResponse,
    TVORA4_GATESELFSUPPORTING: Tvora4_GateSelfSupportingResponse,
    TVORA5_GATEWAY: Tvora5_GatewayResponse,
    TVORA5_GATEGATEWAY: Tvora5_GateGatewayResponse,
    TVORA5_GATETILTING: Tvora5_GateTiltingResponse,
    TVORA5_GATESELFSUPPORTING: Tvora5_GateSelfSupportingResponse,
    VMS_GATEWAY: Vms_GatewayResponse,
    VMS_GATETILTING: Vms_GateTiltingResponse,
    ECO_GATEWAY: Eco_GatewayResponse,
    ECO_GATETILTING: Eco_GateTiltingResponse,
    HIGHWAY_GATEWAY: Highway_GatewayResponse,
    HIGHWAY_GATETILTING: Highway_GateTiltingResponse,
    SOLAR_PANEL_POLE_SOLAR_PANEL_POLE: SolarPanelPole_PoleSolarPanelReponse,
    TABLE_SOLAR_PANEL_TABLE_SOLAR_PANEL: TableSolarPanel_TableSolarPanelsResponse,
    CARPORT_BASEPOLYCARBONATE: Carport_BasePolycarbonateResponse,
    CARPORT_BASEPLATE: Carport_BasePlateResponse,
    CARPORT_BASESOLARPANELS: Carport_BaseSolarPanelsResponse,
    CARPORT_EXTENSIONPOLYCARBONATE: Carport_ExtensionPolycarbonateResponse,
    CARPORT_EXTENSIONPLATE: Carport_ExtensionPlateResponse,
    CARPORT_EXTENSIONSOLARPANELS: Carport_ExtensionSolarPanelsResponse,
    BALLAST_BALLAST: Ballast_Ballast_Response,
    BALLAST_WINDCHEST: Ballast_Windchest_Response,
    EQ_EQ: EntranceResponse,
    /* eslint-enable camelcase */
  };

  if (!Object.prototype.hasOwnProperty.call(components, tag)) {
    throw new Error(`Component (${tag}) not found.`);
  }

  const Component = components[tag];

  return (
    <Component response={response} values={values} hdg={hdg} isPdf />
  );
};

PdfResponseComponent.propTypes = {
  tag: PropTypes.string.isRequired,
  response: PropTypes.object.isRequired,
};

export default PdfResponseComponent;
