/* eslint-disable camelcase */
import React from 'react';
import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateForm as updateFormAction, addNextForm as addNextFormAction } from 'actions';
import PP002P82 from '../PP002P82/PP002P82';
import PP002P82_0 from '../PP002P82_0/PP002P82_0';
import TwoD from '../TwoD/TwoD';
import TwoDS from '../TwoDS/TwoDS';
import ThreeD4 from '../ThreeD4/ThreeD4';
import ThreeD5 from '../ThreeD5/ThreeD5';
import PB001 from '../PB001/PB001';
import PP001C from '../PP001C/PP001C';
import PP001CC from '../PP001CC/PP001CC';
import PP001W from '../PP001W/PP001W';
import PP001N from '../PP001N/PP001N';
import PP002P82Colore1 from '../PP002P82Colore/PP002P82Colore';
import PP002P82Colore1_0 from '../PP002P82Colore_0/PP002P82Colore_0';
import PP002P82Colore2 from '../PP002P82Colore2/PP002P82Colore2';
import PP002P82Colore2_0 from '../PP002P82Colore2_0/PP002P82Colore2_0';

import './OrderForm.css';
import {
  MODEL_COMPONENT_2D,
  MODEL_COMPONENT_2DS,
  MODEL_COMPONENT_3D4,
  MODEL_COMPONENT_3D5,
  MODEL_COMPONENT_PP002P82,
  MODEL_COMPONENT_PP002P82_0,
  MODEL_COMPONENT_PB001,
  MODEL_COMPONENT_PP001C,
  MODEL_COMPONENT_PP001CC,
  MODEL_COMPONENT_PP001W,
  MODEL_COMPONENT_PP001N,
  MODEL_COMPONENT_PP002P82COLORE1,
  MODEL_COMPONENT_PP002P82COLORE1_0,
  MODEL_COMPONENT_PP002P82COLORE2,
  MODEL_COMPONENT_PP002P82COLORE2_0,
  MODEL_COMPONENT_PP002P82NOCE1,
  MODEL_COMPONENT_PP002P82NOCE1_0,
  MODEL_COMPONENT_PP002P82NOCE2,
  MODEL_COMPONENT_PP002P82NOCE2_0,
  MODEL_COMPONENT_PP002P82PINO1,
  MODEL_COMPONENT_PP002P82PINO1_0,
  MODEL_COMPONENT_PP002P82PINO2,
  MODEL_COMPONENT_PP002P82PINO2_0,
  MODEL_COMPONENT_PP002,
  MODEL_COMPONENT_PP002DIVERSO,
  MODEL_COMPONENT_PP002P,
  MODEL_COMPONENT_PP001P46,
  MODEL_COMPONENT_PP002P64,
  MODEL_COMPONENT_PP002P64V,
  MODEL_COMPONENT_PP002P102,
  MODEL_COMPONENT_PP002P102_0,
  MODEL_COMPONENT_PP002P82MIX,
  MODEL_COMPONENT_P302,
  MODEL_COMPONENT_PS002,
  MODEL_COMPONENT_PS003,
  MODEL_COMPONENT_PS004,
  MODEL_COMPONENT_PS004_2,
  MODEL_COMPONENT_PS005,
  MODEL_COMPONENT_PS001,
  MODEL_COMPONENT_STILE,
  MODEL_COMPONENT_VERTICALE,
  MODEL_COMPONENT_VERTICALE_2,
  MODEL_COMPONENT_VERTICALE_3,
  MODEL_COMPONENT_VERTICALE_LM,
  MODEL_COMPONENT_PB003,
  MODEL_COMPONENT_PB155,
  MODEL_COMPONENT_TVORA1,
  MODEL_COMPONENT_TVORA2,
  MODEL_COMPONENT_TVORA3,
  MODEL_COMPONENT_TVORA4,
  MODEL_COMPONENT_VMS,
  MODEL_COMPONENT_ECO,
  MODEL_COMPONENT_TVORA5,
  MODEL_COMPONENT_PP002DIVERSO1,
  MODEL_COMPONENT_PP002DIVERSO2,
  MODEL_COMPONENT_PP002DIVERSO3,
  MODEL_COMPONENT_P302_PB002,
  MODEL_COMPONENT_P302_PS005,
  MODEL_COMPONENT_HIGHWAY,
  MODEL_SOLAR_PANEL_POLE,
  MODEL_COMPONENT_SOLAR_PANEL_POLE,
  MODEL_TABLE_SOLAR_PANEL,
  MODEL_COMPONENT_TABLE_SOLAR_PANEL,
  MODEL_COMPONENT_CARPORT,
  MODEL_COMPONENT_BALLAST,
  MODEL_COMPONENT_EQ,
} from '../../ModelsConstants';
import OrderFormFenceType from './OrderFormFenceType';
import OrderFormHeader from './OrderFormHeader';
import OrderFormVersionBtn from './OrderFormVersionBtn';
import OrderFormComment from './OrderFormComment';
import OrderFormProductSingular from '../../containers/OrderForm/OrderFormProductSingular';
import OrderFormFenceModel from './OrderFormFenceModel';
import OrderFormQuantity from './OrderFormQuantity';
import PP002P82NOCE2 from '../PP002P82Noce2/PP002P82Noce2';
import PP002P82NOCE2_0 from '../PP002P82Noce2_0/PP002P82Noce2_0';
import PP002P82NOCE1 from '../PP002P82Noce/PP002P82Noce';
import PP002P82NOCE1_0 from '../PP002P82Noce_0/PP002P82Noce_0';
import PP002P82PINO1 from '../PP002P82Pino/PP002P82Pino';
import PP002P82PINO1_0 from '../PP002P82Pino_0/PP002P82Pino_0';
import PP002P82PINO2 from '../PP002P82Pino2/PP002P82Pino2';
import PP002P82PINO2_0 from '../PP002P82Pino2_0/PP002P82Pino2_0';
import PP002 from '../PP002/PP002';
import PP002DIVERSO from '../PP002Diverso/PP002Diverso';
import PP002DIVERSO1 from '../PP002Diverso1/PP002Diverso1';
import PP002DIVERSO2 from '../PP002Diverso2/PP002Diverso2';
import PP002DIVERSO3 from '../PP002Diverso3/PP002Diverso3';
import PP002P from '../PP002P/PP002P';
import PP001P46 from '../PP001P46/PP001P46';
import PP002P64 from '../PP002P64/PP002P64';
import PP002P64V from '../PP002P64V/PP002P64V';
import PP002P102 from '../PP002P102/PP002P102';
import PP002P102_0 from '../PP002P102_0/PP002P102_0';
import PP002P82MIX from '../PP002P82Mix/PP002P82Mix';
import PS002 from '../PS002/PS002';
import PS003 from '../PS003/PS003';
import PS004 from '../PS004/PS004';
import PS004_2 from '../PS004_2/PS004_2';
import PS005 from '../PS005/PS005';
import PS001 from '../PS001/PS001';
import STILE from '../Stile/Stile';
import Verticale from '../Verticale/Verticale';
import Verticale_2 from '../Verticale_2/Verticale_2';
import Verticale_3 from '../Verticale_3/Verticale_3';
import Verticale_LM from '../Verticale_LM/Verticale_LM';
import PB003 from '../PB003/PB003';
import PB155 from '../PB155/PB155';
import Tvora1 from '../Tvora1/Tvora1';
import Tvora2 from '../Tvora2/Tvora2';
import Tvora3 from '../Tvora3/Tvora3';
import Tvora4 from '../Tvora4/Tvora4';
import Vms from '../Vms/Vms';
import Eco from '../Eco/Eco';
import Tvora5 from '../Tvora5/Tvora5';
import P302 from '../P302/P302';
import P302PB002 from '../P302PB002/P302PB002';
import P302PS005 from '../P302PS005/P302PS005';
import OrderFormFenceColor from './OrderFormFenceColor';
import Highway from '../Highway/Highway';
import SolarPanelPole from '../PolesSolarPanels/SolarPanelPole';
import TableSolarPanel from '../TableSolarPanel/TableSolarPanel';
import Carport from '../Carport/Carport';
import Ballast from '../Ballast/Ballast';
import Eq from '../Eq/Eq';
import OrderFormHeaderCustom from './OrderFormHeaderCustom';
import SaveMaterialsAsHTML from '../Input/SaveCalc';
// import {
//   FENCE_TYPE_SPAN,
// } from '../../FenceTypesConstants';
import GeneralInfo from '../Response/GeneralInfo';

const OrderForm = ({
  forms, form,
}) => {
  const dispatch = useDispatch();
  const addNextForm = (f) => dispatch(addNextFormAction(f));
  const updateForm = (f) => dispatch(updateFormAction(f));

  const ModelComponent = () => {
    if (!form.model || (!form.type && (form.model !== MODEL_SOLAR_PANEL_POLE && form.model !== MODEL_TABLE_SOLAR_PANEL))) {
      return null;
    }

    const components = {
      [MODEL_COMPONENT_PP002P82]: PP002P82,
      [MODEL_COMPONENT_PP002P82_0]: PP002P82_0,
      [MODEL_COMPONENT_2D]: TwoD,
      [MODEL_COMPONENT_2DS]: TwoDS,
      [MODEL_COMPONENT_3D4]: ThreeD4,
      [MODEL_COMPONENT_3D5]: ThreeD5,
      [MODEL_COMPONENT_PB001]: PB001,
      [MODEL_COMPONENT_PB003]: PB003,
      [MODEL_COMPONENT_PB155]: PB155,
      [MODEL_COMPONENT_PP001C]: PP001C,
      [MODEL_COMPONENT_PP001CC]: PP001CC,
      [MODEL_COMPONENT_PP001W]: PP001W,
      [MODEL_COMPONENT_PP001N]: PP001N,
      [MODEL_COMPONENT_PP002P82COLORE1]: PP002P82Colore1,
      [MODEL_COMPONENT_PP002P82COLORE1_0]: PP002P82Colore1_0,
      [MODEL_COMPONENT_PP002P82COLORE2]: PP002P82Colore2,
      [MODEL_COMPONENT_PP002P82COLORE2_0]: PP002P82Colore2_0,
      [MODEL_COMPONENT_PP002P82NOCE1]: PP002P82NOCE1,
      [MODEL_COMPONENT_PP002P82NOCE1_0]: PP002P82NOCE1_0,
      [MODEL_COMPONENT_PP002P82NOCE2]: PP002P82NOCE2,
      [MODEL_COMPONENT_PP002P82NOCE2_0]: PP002P82NOCE2_0,
      [MODEL_COMPONENT_PP002P82PINO1]: PP002P82PINO1,
      [MODEL_COMPONENT_PP002P82PINO1_0]: PP002P82PINO1_0,
      [MODEL_COMPONENT_PP002P82PINO2]: PP002P82PINO2,
      [MODEL_COMPONENT_PP002P82PINO2_0]: PP002P82PINO2_0,
      [MODEL_COMPONENT_PP002P82MIX]: PP002P82MIX,
      [MODEL_COMPONENT_PP002]: PP002,
      [MODEL_COMPONENT_PP002DIVERSO]: PP002DIVERSO,
      [MODEL_COMPONENT_PP002DIVERSO1]: PP002DIVERSO1,
      [MODEL_COMPONENT_PP002DIVERSO2]: PP002DIVERSO2,
      [MODEL_COMPONENT_PP002DIVERSO3]: PP002DIVERSO3,
      [MODEL_COMPONENT_PP002P]: PP002P,
      [MODEL_COMPONENT_PP001P46]: PP001P46,
      [MODEL_COMPONENT_PP002P64]: PP002P64,
      [MODEL_COMPONENT_PP002P64V]: PP002P64V,
      [MODEL_COMPONENT_PP002P102]: PP002P102,
      [MODEL_COMPONENT_PP002P102_0]: PP002P102_0,
      [MODEL_COMPONENT_P302]: P302,
      [MODEL_COMPONENT_P302_PB002]: P302PB002,
      [MODEL_COMPONENT_P302_PS005]: P302PS005,
      [MODEL_COMPONENT_PS001]: PS001,
      [MODEL_COMPONENT_PS002]: PS002,
      [MODEL_COMPONENT_PS003]: PS003,
      [MODEL_COMPONENT_PS004]: PS004,
      [MODEL_COMPONENT_PS004_2]: PS004_2,
      [MODEL_COMPONENT_PS005]: PS005,
      [MODEL_COMPONENT_STILE]: STILE,
      [MODEL_COMPONENT_TVORA1]: Tvora1,
      [MODEL_COMPONENT_TVORA2]: Tvora2,
      [MODEL_COMPONENT_TVORA3]: Tvora3,
      [MODEL_COMPONENT_TVORA4]: Tvora4,
      [MODEL_COMPONENT_TVORA5]: Tvora5,
      [MODEL_COMPONENT_VERTICALE]: Verticale,
      [MODEL_COMPONENT_VERTICALE_2]: Verticale_2,
      [MODEL_COMPONENT_VERTICALE_3]: Verticale_3,
      [MODEL_COMPONENT_VERTICALE_LM]: Verticale_LM,
      [MODEL_COMPONENT_VMS]: Vms,
      [MODEL_COMPONENT_ECO]: Eco,
      [MODEL_COMPONENT_HIGHWAY]: Highway,
      [MODEL_COMPONENT_SOLAR_PANEL_POLE]: SolarPanelPole,
      [MODEL_COMPONENT_TABLE_SOLAR_PANEL]: TableSolarPanel,
      [MODEL_COMPONENT_CARPORT]: Carport,
      [MODEL_COMPONENT_BALLAST]: Ballast,
      [MODEL_COMPONENT_EQ]: Eq,
    };

    if (!Object.prototype.hasOwnProperty.call(components, form.model)) {
      throw new Error(`Component (${form.model}) not found.`);
    }

    const Component = components[form.model];

    return (
      <Component form={form} addNextForm={addNextForm} updateForm={updateForm} />
    );
  };

  const show = form.show ? 'show' : '';

  const isProductSingularUnique = !forms.some((f) => f.id !== form.id && f.productSingular === form.productSingular);
  const suggestedModel = forms[0].model || null;
  const suggestedColor = forms[0].color || null;
  const modelComponent = isProductSingularUnique ? <ModelComponent form={form} /> : null;
  const header = <OrderFormHeaderCustom form={form} updateForm={updateForm} />;
  const readyToSave = (sessionStorage.getItem('save') === 'true' && modelComponent?.props?.form?.response?.order !== undefined && modelComponent?.props?.form?.response?.order === Number(sessionStorage.getItem('order')));
  if (Number(sessionStorage.getItem('std')) === 1 && readyToSave) {
    const modelComponentComplete = isProductSingularUnique ? (
      <>
        <GeneralInfo form={form} />
        {modelComponent}
      </>
    ) : null;
    sessionStorage.setItem('modelComponent', ReactDomServer.renderToStaticMarkup(modelComponentComplete));
    sessionStorage.setItem('header', ReactDomServer.renderToStaticMarkup(header));
    SaveMaterialsAsHTML();
    sessionStorage.setItem('save', false);
  }

  return (
    <section className="container-xl orderForm">
      <div className="accordion">
        <div className="row">
          <div className="col">
            <div className="card">
              {window.location.href.includes('test.production.konsport') && <OrderFormVersionBtn />}
              <OrderFormHeader form={form} updateForm={updateForm} />
              <article className={`collapse ${show}`}>
                <div className="card-body">
                  <div className="container-xl">
                    <div className="row">
                      <div className="col">
                        <OrderFormProductSingular form={form} updateForm={updateForm} />
                        {isProductSingularUnique && <OrderFormQuantity form={form} updateForm={updateForm} />}
                        {isProductSingularUnique && (
                        <OrderFormFenceModel
                          form={form}
                          updateForm={updateForm}
                          suggestedModel={suggestedModel}
                        />
                        )}
                        {isProductSingularUnique && <OrderFormFenceColor form={form} updateForm={updateForm} suggestedColor={suggestedColor} />}
                        {isProductSingularUnique && <OrderFormFenceType form={form} updateForm={updateForm} />}
                        {isProductSingularUnique && <ModelComponent form={form} />}
                        {isProductSingularUnique && <OrderFormComment form={form} updateForm={updateForm} />}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

OrderForm.propTypes = {
  forms: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
};

export default OrderForm;
