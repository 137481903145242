import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Poles, { POLES_LACK } from '../Input/Poles';
import RivetNutPole from '../Input/RivetNutPole';
import MaskingRod from '../Input/MaskingRod';
import Holder, {
  HOLDER_FORM_NAME,
  HOLDER_NO,
} from '../Input/Holder';
import CoverBetweenWings from '../Input/CoverBetweenWings';
import Hinges from '../Input/Hinges';
import FormActions from '../Input/FormActions';
import { FENCE_TYPE_NON_SYMMETRICAL_GATE } from '../../FenceTypesConstants';
import Machine, { MACHINE_YES } from '../Input/Machine';
import NonSymmetricalGateResponse from './NonSymmetricalGateResponse';
import invoke from '../../lambda';
import MachinePosition from '../Input/MachinePosition';
import ModuleHeight from '../Input/ModuleHeight';

const NonSymmetricalGate = ({ form, updateForm }) => {
  const [showMachineInput, setShowMachineInput] = useState(false);
  const formik = useFormik({
    initialValues: {
      height: form.values.height || '',
      leftWingWidth: form.values.leftWingWidth || '',
      rightWingWidth: form.values.rightWingWidth || '',
      moduleHeight: form.values.moduleHeight || '',
      poles: form.values.poles || '',
      rivetNutPole: form.values.rivetNutPole || '',
      holder: form.values.holder || '',
      hinges: form.values.hinges || '',
      machine: form.values.machine || '',
      machinePosition: form.values.machinePosition || '',
      coverBetweenWings: form.values.coverBetweenWings || '',
      maskingRod: form.values.maskingRod || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const res = await invoke({
        amount: form.quantity,
        model: 'P302 + PB002',
        type: 'uchylna',
        assymetry: 'BRAMA NIESYMETRYCZNA',
        ...values,
      });
      const newForm = {
        ...form,
        values: {
          ...values,
        },
        response: {
          ...res.products[0],
        },
      };

      setSubmitting(false);
      updateForm(newForm);
    },
    validate: ({
      height, leftWingWidth, rightWingWidth, moduleHeight,
    }) => {
      const errors = {};

      if (!height) {
        errors.height = 'Wymagana wartość';
      } else if (height < 500) {
        errors.height = 'Minimalna wysokość to 500mm.';
      } else if (height > 2300) {
        errors.height = 'Maksymalna wysokość to 2300mm.';
      }
      if (!leftWingWidth) {
        errors.leftWingWidth = 'Wymagana wartość';
      } else if (leftWingWidth < 500) {
        errors.leftWingWidth = 'Minimalna szerokość to 500mm.';
      } else if (leftWingWidth > 3750) {
        errors.leftWingWidth = 'Maksymalna szerokość to 3750mm.';
      }
      if (!rightWingWidth) {
        errors.rightWingWidth = 'Wymagana wartość';
      } else if (rightWingWidth < 500) {
        errors.rightWingWidth = 'Minimalna szerokość to 500mm.';
      } else if (rightWingWidth > 3750) {
        errors.rightWingWidth = 'Maksymalna szerokość to 3750mm.';
      }
      if (!moduleHeight) {
        errors.moduleHeight = 'Wymagana wartość';
      } else if (moduleHeight < 100) {
        errors.moduleHeight = 'Minimalna wysokość to 100mm.';
      } else if (moduleHeight > 1390) {
        errors.moduleHeight = 'Maksymalna wysokość to 1390mm.';
      }
      return errors;
    },
  });
  useEffect(() => {
    if (formik.values.leftWingWidth < 3000 && formik.values.rightWingWidth < 3000) {
      setShowMachineInput(true);
    } else {
      setShowMachineInput(false);
    }
  }, [formik.values.leftWingWidth, formik.values.rightWingWidth]);
  useEffect(() => {
    if (formik.values.poles === POLES_LACK) {
      formik.setFieldValue(HOLDER_FORM_NAME, HOLDER_NO);
    }
  }, [formik.values.poles]);

  const isResponseAvailable = Object.keys(form.response).length > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formInputs">
        <div className="form-group">
          <label htmlFor={`height__${form.id}`}>Wysokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`height__${form.id}`}
            name="height"
            value={formik.values.height}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.height && formik.errors.height}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`leftWingWidth__${form.id}`}>Lewe skrzydło szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`leftWingWidth__${form.id}`}
            name="leftWingWidth"
            value={formik.values.leftWingWidth}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.leftWingWidth && formik.errors.leftWingWidth}</span>
        </div>
        <div className="form-group">
          <label htmlFor={`rightWingWidth__${form.id}`}>Prawe skrzydło szerokość (mm)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Wpisz"
            id={`rightWingWidth__${form.id}`}
            name="rightWingWidth"
            value={formik.values.rightWingWidth}
            autoComplete="off"
            onChange={formik.handleChange}
            required
          />
          <span className="error">{formik.errors.rightWingWidth && formik.errors.rightWingWidth}</span>
        </div>
        <div>
          <ModuleHeight
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.moduleHeight}
          />
          <span className="error">{formik.errors.moduleHeight && formik.errors.moduleHeight}</span>
        </div>
        <Poles
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_NON_SYMMETRICAL_GATE}
          value={formik.values.poles}
        />
        {formik.values.poles && formik.values.poles !== POLES_LACK && (
          <RivetNutPole
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.rivetNutPole}
          />
        )}
        <Holder
          formId={form.id}
          onChange={formik.handleChange}
          poles={formik.values.poles}
          value={formik.values.holder}
        />
        <Hinges
          formId={form.id}
          onChange={formik.handleChange}
          fenceType={FENCE_TYPE_NON_SYMMETRICAL_GATE}
          value={formik.values.hinges}
        />
        {formik.values.hinges && formik.values.hinges === 'standard' && formik.values.leftWingWidth && formik.values.rightWingWidth && Number(formik.values.rightWingWidth) < 3000 && Number(formik.values.leftWingWidth) < 3000 && (
          <MaskingRod
            formId={form.id}
            onChange={formik.handleChange}
            value={formik.values.maskingRod}
          />
        )}
        {showMachineInput && (
        <Machine
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.machine}
        />
        )}
        {showMachineInput && formik.values.machine === MACHINE_YES && (
        <MachinePosition
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.machinePosition}
        />
        )}
        <CoverBetweenWings
          formId={form.id}
          onChange={formik.handleChange}
          value={formik.values.coverBetweenWings}
        />
      </div>
      <FormActions isSubmitting={formik.isSubmitting} />
      {form.response?.error && (
        <h2 style={{ color: 'red' }}>
          Wystąpił błąd:
          {' '}
          {form.response.error}
        </h2>
      )}
      {isResponseAvailable && !form.response?.error && <NonSymmetricalGateResponse response={form.response} isPdf={false} />}
    </form>
  );
};

NonSymmetricalGate.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default NonSymmetricalGate;
