import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';

import Home from 'Pages/Home';
import ProductionMaterials from 'Pages/ProductionMaterials';
import ForgottenPassword from 'Pages/ForgottenPassword/ForgottenPassword';
import NewPassword from 'Pages/NewPassword/NewPassword';
import CalculationResult from 'Pages/CalculationResult';
import MaterialCard from 'Pages/materialCard/MaterialCard';
import MaterialCardMaterials from 'Pages/ProductionMaterials/indexCard';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { loadUser, loadUserWorkingPlace } from '../auth';

export const ROUTE_HOME = '/';
export const ROUTE_PRODUCTION_MATERIALS = '/orders/:orderId/production-materials';
export const ROUTE_FORGOTTEN_PASSWORD = '/reset-password';
export const ROUTE_NEW_PASSWORD = '/new-password/:requestId';
export const ROUTE_SHOW_CALCULATION = '/production-materials-manually-calculated/:zamowieniaId/show';
export const ROUTE_MATERIAL_CARD = '/material-card';
export const ROUTE_MATERIAL_CARD_MATERIALS = '/materials/:orderId/production-materials';

const isUserAllowedForMaterialCard = () => {
  const forbiddenWorkingPlaces = [
    'SEMI_FINISHED_PRODUCTS_PRODUCTION',
    'WELDING-LASER',
    'WELDING-CUTTING',
    'WELDING-MOUNTING',
    'WELDING-MOUNTING-ON-BOARD',
    'WELDING-ANGLES',
    'WELDING-WELDING',
    'SEMI-FINISHED-PRODUCTS-RUBBERING',
    'WAREHOUSE-WRAPPING',
    'WAREHOUSE-EQUIPMENT',
    'WAREHOUSE-LOADING',
    'PAINT-SHOP-HANGING',
    'PAINT-SHOP-PAINTING',
    'SEMI-FINISHED-PRODUCTS-METAL-ROLLING',
    'SEMI-FINISHED-PRODUCTS-LASER',
  ];
  const workingPlace = loadUserWorkingPlace();
  if (workingPlace !== '' && forbiddenWorkingPlaces.includes(workingPlace)) {
    return false;
  }
  return true;
};

export const ProtectedProductionMaterials = () => {
  const user = loadUser();
  if (!user || !isUserAllowedForMaterialCard()) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  return <ProductionMaterials />;
};

export const ProtectedMaterialCard = () => {
  const user = loadUser();
  if (!user || !isUserAllowedForMaterialCard()) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  return <MaterialCard />;
};

export const ProtectedMaterialCardMaterials = () => {
  const user = loadUser();
  if (!user || !isUserAllowedForMaterialCard()) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  return <MaterialCardMaterials />;
};

const App = () => (
  <Router>
    <Switch>
      <Route path={ROUTE_HOME} component={Home} exact />
      <Route
        path={ROUTE_PRODUCTION_MATERIALS}
        component={ProtectedProductionMaterials}
      />
      <Route path={ROUTE_FORGOTTEN_PASSWORD} component={ForgottenPassword} />
      <Route path={ROUTE_NEW_PASSWORD} component={NewPassword} />
      <Route path={ROUTE_SHOW_CALCULATION} component={CalculationResult} />
      <Route path={ROUTE_MATERIAL_CARD} component={ProtectedMaterialCard} />
      <Route path={ROUTE_MATERIAL_CARD_MATERIALS} component={ProtectedMaterialCardMaterials} />
    </Switch>
  </Router>
);

export default App;
