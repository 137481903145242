import React from 'react';
import PropTypes from 'prop-types';

export const VerticaleLMGateSelfSupportingResponseSecondPage = ({ form }) => {
  const gearRailCombined = Object.keys(form.response.productionMaterials.gearRailCombined).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{form.response.productionMaterials.gearRailCombined[key].name}</td>
      <td>{form.response.productionMaterials.gearRailCombined[key].material}</td>
      <td>
        {form.response.productionMaterials.gearRailCombined[key].amount.value}
        {' '}
        {form.response.productionMaterials.gearRailCombined[key].amount.unit}
      </td>
      <td />
      <td className="tdCenter">
        <div className="pdfSquare" />
      </td>
    </tr>
  ));

  const storage = Object.keys(form.response.productionMaterials.storage).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{form.response.productionMaterials.storage[key].name}</td>
      <td>{form.response.productionMaterials.storage[key].material}</td>
      <td>
        {form.response.productionMaterials.storage[key].amount.value}
        {' '}
        {form.response.productionMaterials.storage[key].amount.unit}
      </td>
      <td />
      <td className="tdCenter">
        <div className="pdfSquare" />
      </td>
    </tr>
  ));

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">MAGAZYN - OSPRZĘT</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th>{' '}</th>
            <th className="responseThSaws">OSPRZĘT</th>
          </tr>
        </thead>
        <tbody>
          {storage}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan="7">MAGAZYN - ZBROJENIE</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th>{' '}</th>
            <th className="responseThSaws">ZBROJENIE</th>
          </tr>
        </thead>
        <tbody>
          {gearRailCombined}
        </tbody>
      </table>
    </>
  );
};

const GateSelfSupportingResponse = ({ response, isPdf }) => {
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const buffer = Object.keys(response.productionMaterials.buffer).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.buffer[key].name}</td>
      <td>{response.productionMaterials.buffer[key].material}</td>
      <td>
        {response.productionMaterials.buffer[key].amount.value}
        {' '}
        {response.productionMaterials.buffer[key].amount.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const gearRailCombined = Object.keys(response.productionMaterials.gearRailCombined).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gearRailCombined[key].name}</td>
      <td>{response.productionMaterials.gearRailCombined[key].material}</td>
      <td>
        {response.productionMaterials.gearRailCombined[key].amount.value}
        {' '}
        {response.productionMaterials.gearRailCombined[key].amount.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const storage = Object.keys(response.productionMaterials.storage).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.storage[key].name}</td>
      <td>{response.productionMaterials.storage[key].material}</td>
      <td>
        {response.productionMaterials.storage[key].amount.value}
        {' '}
        {response.productionMaterials.storage[key].amount.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const welding = Object.keys(response.productionMaterials.welding).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.welding[key].name}</td>
      <td>{response.productionMaterials.welding[key].material}</td>
      <td>
        {response.productionMaterials.welding[key].amount.value}
        {' '}
        {response.productionMaterials.welding[key].amount.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const varnishing = Object.keys(response.productionMaterials.varnishing).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.varnishing[key].name}</td>
      <td>{response.productionMaterials.varnishing[key].material}</td>
      <td>
        {response.productionMaterials.varnishing[key].amount.value}
        {' '}
        {response.productionMaterials.varnishing[key].amount.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  return (
    <div>
      {isPdf && (
        <>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="7">INFORMACJE OGÓLNE</th>
              </tr>
            </thead>
          </table>
          <div>
            <p>
              <b>Rozstaw krańcowy: </b>
              {response.productionMaterials?.info.spacing.size.value}
              {' mm | '}
              <b>Waga: </b>
              {response.productionMaterials?.info.weight.amount.value}
              {' kg'}
            </p>
            <p>
              <b>Całkowita długość bramy: </b>
              {response.productionMaterials?.info.totalGateLength}
              {' mm | '}
              <b>Kierunek otwierania: </b>
              {response.productionMaterials?.info.direction}
            </p>
          </div>
        </>
      )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>SPAWALNIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">SPAWALNIA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {welding}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>BUFOR</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">BUFOR</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {buffer}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LAKIEROWANIE</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">LAKIEROWANIE</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {varnishing}
        </tbody>
      </table>
      {!isPdf && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OSPRZĘT</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">OSPRZĘT</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {storage}
          </tbody>
        </table>
      )}
      {!isPdf && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - ZBROJENIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">ZBROJENIE</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gearRailCombined}
          </tbody>
        </table>
      )}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
