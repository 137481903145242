import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import Footer from 'components/Footer';
import SignIn from 'Pages/SignIn/SignIn';
import OrderFormRecreated from 'components/OrderForm/OrderFormRecreated';
import GenerateXLS from 'Pages/ProductionMaterials/xls';
import Header from 'Pages/shared/header';
import UploadToGoogleDrive from './drive';

const CalculationResult = () => {
  const { app: appState } = useSelector((s) => s);
  const { authenticated } = appState;
  const { zamowieniaId } = useParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getCalculation = async () => {
      try {
        const response = await axios.get(`/production-materials-manually-calculated/${zamowieniaId}`);
        if (response && response.status === 200) {
          setProducts(response.data.products);
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.error(`Error while fetching calculation results: ${error}`);
      }
    };
    getCalculation();
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        UploadToGoogleDrive(document.documentElement.outerHTML, zamowieniaId);
      }
    });
  }, []);

  const render = products.map((product, index) => (
    /* eslint-disable react/no-danger */
    <OrderFormRecreated header={<div dangerouslySetInnerHTML={{ __html: product.header }} />} materialsHtml={<div dangerouslySetInnerHTML={{ __html: product.productionMaterialsAsHtml }} />} zamowieniaId={zamowieniaId} index={index} />
  ));

  const generateXLS = () => {
    GenerateXLS(products, [zamowieniaId]);
  };

  if (!authenticated) {
    return (
      <>
        <main>
          <SignIn />
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <main>
        <Header />
        <div id="tables-container">
          {render}
        </div>
        <XLSBtn className="btn btn-danger" onClick={generateXLS}>
          <b>Generuj xls</b>
        </XLSBtn>
      </main>
      <Footer />
    </>
  );
};

export default CalculationResult;

const XLSBtn = styled.button`
  font-size: 0.9rem;
  position: fixed;
  left: 44%;
  transform: translateX(630px);
  top: 120px;
  @media print {
    display: none;
  }
`;
