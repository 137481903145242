import axios from 'axios';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

export const doesUserHasRole = (role, roles) => (roles ? roles.some((r) => r === role) : false);

export const signOut = (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  axios.defaults.headers.common.Authorization = '';
  dispatch({ type: 'SIGN_OUT' });
};

export const signOutOnError = (dispatch, error) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  axios.defaults.headers.common.Authorization = '';
  dispatch({ type: 'SIGN_OUT_ON_ERROR', error });
};

export const setTokens = (token, refreshToken) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const loadUserFromToken = (token) => {
  try {
    return jwtDecode(token);
  } catch {
    return null;
  }
};

export const loadUser = () => {
  if (!localStorage.getItem('token') || !localStorage.getItem('refreshToken')) return null;

  return jwtDecode(localStorage.getItem('token'));
};

export const isUserAllowedForMaterialCard = () => localStorage.getItem('isUserAllowedForMaterialCard') === 'true';

export const loadUserWorkingPlace = () => localStorage.getItem('workingPlace');

export const loadAndSetUserWorkingPlace = () => {
  axios
    .get('/users/me')
    .then(({ data }) => {
      localStorage.setItem('workingPlace', data?.user?.attributes?.workingPlace);
      const forbiddenWorkingPlaces = [
        'SEMI_FINISHED_PRODUCTS_PRODUCTION',
        'WELDING-LASER',
        'WELDING-CUTTING',
        'WELDING-MOUNTING',
        'WELDING-MOUNTING-ON-BOARD',
        'WELDING-ANGLES',
        'WELDING-WELDING',
        'SEMI-FINISHED-PRODUCTS-RUBBERING',
        'WAREHOUSE-WRAPPING',
        'WAREHOUSE-EQUIPMENT',
        'WAREHOUSE-LOADING',
        'PAINT-SHOP-HANGING',
        'PAINT-SHOP-PAINTING',
        'SEMI-FINISHED-PRODUCTS-METAL-ROLLING',
        'SEMI-FINISHED-PRODUCTS-LASER',
      ];
      const workingPlace = data?.user?.attributes?.workingPlace;
      if (workingPlace !== '' && forbiddenWorkingPlaces.includes(workingPlace)) {
        localStorage.setItem('isUserAllowedForMaterialCard', 'false');
      } else {
        localStorage.setItem('isUserAllowedForMaterialCard', 'true');
      }
    })
    .catch((err) => {
      console.warn(err);
    });
};

const now = dayjs();

export const calcTokenDiff = (user) => {
  const userExp = user?.exp ?? null;
  return userExp ? dayjs.unix(userExp).diff(now, 'ms') : 0;
};
