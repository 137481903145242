import React from 'react';
import PropTypes from 'prop-types';
import {
  MODEL_2D,
  MODEL_2DS,
  MODEL_3D_4,
  MODEL_3D_5,
  MODEL_ECO,
  MODEL_PP002,
  MODEL_VMS,
} from '../../ModelsConstants';
import { modelsWithoutColor } from './OrderFormFenceType';

export const COLOR_RAL = 'colorRal';
export const COLOR_HDG = 'colorHdg';
export const COLOR_7016 = 'colorRal7016';

export const colorsInPolish = {
  [COLOR_RAL]: 'RAL',
  [COLOR_7016]: 'Ral 7016',
  [COLOR_HDG]: 'Ocynk Ogniowy',
};

const OrderFormFenceColor = ({ form, updateForm, suggestedColor = null }) => {
  if (!form.quantity || !form.model) {
    return null;
  }

  if (suggestedColor && !form.color) {
    updateForm({
      ...form,
      color: suggestedColor,
      values: {},
      response: {},
    });
  }

  const handleChange = (e) => {
    e.preventDefault();

    updateForm({
      ...form,
      color: e.target.value,
      type: null,
      values: {},
      response: {},
    });
  };

  // if ((form.model === MODEL_P302) || form.model === MODEL_P302_PB002 || form.model === MODEL_P302_PS005 || form.model === MODEL_SOLAR_PANEL_POLE || form.model === MODEL_TABLE_SOLAR_PANEL || form.model === MODEL_PP002P82NOCE1_0
  // || form.model === MODEL_PP002P82NOCE2_0 || form.model === MODEL_PP002P82COLORE1_0 || form.model === MODEL_PP002P82COLORE2_0 || form.model === MODEL_PP002P82PINO1_0 || form.model === MODEL_PP002P82PINO2_0 || form.model === MODEL_PS005
  // || form.model === MODEL_VERTICALE_2 || form.model === MODEL_VERTICALE_3 || form.model === MODEL_VERTICALE_LM) {
  //   return null;
  // }
  if (modelsWithoutColor.includes(form.model)) {
    return null;
  }
  if ((form.model !== MODEL_2D && form.model !== MODEL_2DS && form.model !== MODEL_3D_4 && form.model !== MODEL_3D_5 && form.model !== MODEL_VMS && form.model !== MODEL_ECO && form.model !== MODEL_PP002)) {
    return (
      <div className="form-group orderFormFenceColor">
        <label htmlFor={`orderFormFenceColor__${form.id}`}>Wybierz kolor ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceColor__${form.id}`}
          name="fenceColor"
          onChange={handleChange}
          value={form.color || suggestedColor || ''}
          required
        >
          <option value="" disabled>Wybierz kolor ogrodzenia</option>
          <option value={COLOR_RAL}>RAL</option>
          <option value={COLOR_7016}>RAL 7016</option>
        </select>
      </div>
    );
  }
  if (form.model === MODEL_PP002 || form.model === MODEL_2D || form.model === MODEL_2DS || form.model === MODEL_3D_4 || form.model === MODEL_3D_5) {
    return (
      <div className="form-group orderFormFenceColor">
        <label htmlFor={`orderFormFenceColor__${form.id}`}>Wybierz kolor ogrodzenia</label>
        <select
          className="form-control"
          id={`orderFormFenceColor__${form.id}`}
          name="fenceColor"
          onChange={handleChange}
          value={form.color || suggestedColor || ''}
          required
        >
          <option value="" disabled>Wybierz kolor ogrodzenia</option>
          <option value={COLOR_RAL}>RAL</option>
          <option value={COLOR_HDG}>Ocynk Ogniowy</option>
          <option value={COLOR_7016}>RAL 7016</option>
        </select>
      </div>
    );
  }
  return (
    <div className="form-group orderFormFenceColor">
      <label htmlFor={`orderFormFenceColor__${form.id}`}>Wybierz kolor ogrodzenia</label>
      <select
        className="form-control"
        id={`orderFormFenceColor__${form.id}`}
        name="fenceColor"
        onChange={handleChange}
        value={form.color || suggestedColor || ''}
        required
      >
        <option value="" disabled>Wybierz kolor ogrodzenia</option>
        <option value={COLOR_RAL}>RAL</option>
        <option value={COLOR_HDG}>Ocynk Ogniowy</option>
      </select>
    </div>
  );
};

OrderFormFenceColor.propTypes = {
  form: PropTypes.object.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default OrderFormFenceColor;
